import React, { useEffect, useState } from "react";
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import Invoice from "./reports/Invoice";
import { injectIntl } from 'react-intl'
import { Container } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { apiUrl, domainUrl } from "./vars";

export const translations = {
  description: 'Description',
  unite: 'Unité',
  quantite: 'Quantité',
  coutUnitaire: 'Coût unitaire',
  montant: 'Montant',
  type: 'Facture',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  padding: {
    paddingTop: '20px'
  },
  right: {
    textAlign: 'right',
    marginTop: '20px',
    display: 'inline-block',
  }
}));

const Detail = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [invoice, setInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    if (id === 'bulk')
      axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`, {})
        .then(function (response) {
          let ids = [];
          if (id === 'bulk') {
            ids = JSON.parse(localStorage.getItem('bulk'));
          } else {
            ids = id.split(',');
          }

          const { entries } = response.data;
          let array = [];
          ids.map(i => {
            array.push(entries.find(item => (item._id === i)))
          });

          setInvoice(array);
          setIsLoading(false);
        })
        .catch(function (error) {
          // localStorage.clear();
          console.log(error);
        });
    else {
      axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`, { filter: { _id: id } })
        .then((response) => {
          const { entries } = response.data;

          setInvoice([entries[0]]);
          setIsLoading(false);
        })
        .catch(function (error) {
          // localStorage.clear();
          console.log(error);
        });
    }
  }, []);

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const handleSend = async (invoice) => {
    const data = { ...invoice }
    const date = new Date()
    data.send = true;
    data.sendDate = date.getTime();

    axios.post(`${apiUrl}/collections/save/${localStorage.client}?token=${localStorage.token}`, { data })
      .then(function (response) {
        props.getInvoices();
      })
      .catch(function (error) {
        console.log(error);
      });

    const form = invoice;
    const response = await axios.post(`${apiUrl}/collections/get/${localStorage.client}_alerts?filter[invoice]=${form.invoice}&token=${localStorage.token}`, {})
    const alertId = response.data.entries?.[0]?._id

    const originalDate = new Date();
    const newDate = new Date(originalDate);
    newDate.setUTCMonth(originalDate.getUTCMonth() + 1);

    let dataAlert = {};
    if (!alertId) {
      dataAlert = {
        date: newDate.getTime(),
        invoice: form.invoice,
        invoiceId: form._id,
        clientName: form.destination,
        clientCompany: form.company,
        address: form.address,
        zip: form.zip,
        phone: form.phone,
        email: form.email,
        type: "paiement manquant",
        repeat: 30,
        readed: 0
      };
    } else {
      dataAlert = {
        ...response.data.entries?.[0],
        _id: alertId,
        date: newDate.getTime(),
      }
    }

    axios.post(`${apiUrl}/collections/save/${localStorage.client}_alerts?token=${localStorage.token}`, { data: dataAlert })
      .then(function () { })
      .catch(function (error) {
        console.log(error);
      });



  }

  const sendInvoice = async (invoice) => {
    const url = `/uploadPdf.php`;
    const urlSendEmail = `/contact.php`;
    const pdfBuffer = await pdf(<Invoice invoice={invoice} translations={translations} intl={props.intl} />).toBlob();
    const formData = new FormData();
    formData.append('file', pdfBuffer);
    formData.append('client', JSON.parse(localStorage.cltObj || '{}').client);
    formData.append('filename', invoice[0].invoice || invoice[0]._id);

    axios.post(url, formData).then(({ data }) => {
      if (data.status === 200) {
        const formDataEmail = new FormData();
        formDataEmail.append('template_id', "d-a13f7f88524a4d1abc3af1902b6da488");
        formDataEmail.append('file', `${domainUrl}/upload/${JSON.parse(localStorage.cltObj || '{}').client}/invoices/${data.file}.pdf`);
        formDataEmail.append('file_name', `${data.file}.pdf`);
        formDataEmail.append('mail_to', invoice[0].email);
        formDataEmail.append('invoice_no', invoice[0].invoice);
        formDataEmail.append('client', JSON.parse(localStorage.cltObj || '{}').company);
        formDataEmail.append('client_email', JSON.parse(localStorage.cltObj || '{}').email);
        formDataEmail.append('imageLogo', `${domainUrl}${JSON.parse(localStorage.cltObj || '{}').logo.path}`);
        formDataEmail.append('total', props.intl.formatNumber(invoice[0].total, { style: 'currency', currency: 'EUR' }));
        formDataEmail.append('date', props.intl.formatDate(invoice[0].date));
        formDataEmail.append('clientName', invoice[0].clientName);
        formDataEmail.append('bank', invoice?.[0].bank || JSON.parse(localStorage.cltObj || '{}').bank);
        formDataEmail.append('bic', invoice?.[0].bic || JSON.parse(localStorage.cltObj || '{}').bic);
        formDataEmail.append('iban', invoice?.[0].iban || JSON.parse(localStorage.cltObj || '{}').iban);

        axios.post(urlSendEmail, formDataEmail).then((resp) => {
          // console.log('resp', resp)
          NotificationManager.success('La facture a été envoyée.', 'Succès');
          handleSend(invoice[0])
          history.push("/");
        });
      }
    });
  }

  return (
    <div style={{ marginTop: '25px' }}>

      <Container fixed style={{ textAlign: 'center', marginBottom: 20 }}>
        <PDFDownloadLink document={
          <Invoice invoice={invoice} translations={translations} intl={props.intl} />
        } fileName={`${invoice.length === 1 ? invoice[0].invoice : 'Fature'}.pdf`}>
          {({ blob, url, loading, error }) => (loading ? 'Chargement du document ...' : <Button variant="contained" color="primary">Télécharger le document PDF</Button>)}
        </PDFDownloadLink>

        <div style={{ paddingTop: '25px' }}>
          <Button
            variant="contained"
            color={invoice[0].send ? "secondary" : "primary"}
            onClick={() => sendInvoice(invoice)}
            disabled={!invoice[0].email}
          >
            {invoice[0].send && (<>Renvoyer par email</>)}
            {!invoice[0].send && (<>Envoyé par email</>)}
          </Button>
        </div>

      </Container>
      {id !== 'bulk' && (
        <PDFViewer width="950" height="1200" className="pdf-viewer" >
          <Invoice invoice={invoice} translations={translations} intl={props.intl} />
        </PDFViewer>
      )}
    </div>
  );
}

export default injectIntl(Detail);
