import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { FormattedNumber, injectIntl } from 'react-intl'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Backdrop, CircularProgress, Container, Divider, Grid, Paper, makeStyles } from '@material-ui/core';
import { apiUrl } from './vars';
import Radio from '@material-ui/core/Radio';
import MonthlyChart, { sumObjects } from './Dashboard/MonthlyChart';
import QuarterlyChart from './Dashboard/QuarterlyChart';
import YearChart from './Dashboard/YearChart';

const calculatePercentualGrow = (years) => {
  const yearsKeys = Object.keys(years);
  const result = {};

  for (let i = 1; i < yearsKeys.length; i++) {
    const currentYear = yearsKeys[i];
    const lastYear = yearsKeys[i - 1];

    const actualValue = Object.values(years[currentYear] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const lastValue = Object.values(years[lastYear] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const grow = ((actualValue - lastValue) / lastValue) * 100;

    result[currentYear] = grow;
  }

  return result;
}

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

const QUARTERS = [
  '1er Trimestre',
  '2e Trimestre',
  '3e Trimestre',
  '4e Trimestre',
]

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

const Home = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalValuesByYear, setTotalValuesByYear] = React.useState(null);
  const [totalValuesByYearP, setTotalValuesByYearP] = React.useState(null);
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allInvoicesPayed, setAllInvoicesPayed] = useState([]);
  const [allInvoicesUnpayed, setAllInvoicesUnpayed] = useState([]);
  const [totalAllInvoices, setTotalAllInvoices] = React.useState(0);
  const [totalAllInvoicesWithoutNumber, setTotalAllInvoicesWithoutNumber] = React.useState(0);
  const [totalAllInvoicesPayedWithoutNumber, setTotalAllInvoicesPayedWithoutNumber] = React.useState(0);
  const [totalAllInvoicesUnpayedWithoutNumber, setTotalAllInvoicesUnpayedWithoutNumber] = React.useState(0);
  const [period, setPeriod] = React.useState('month');


  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/clientes?token=${localStorage.token}`, { filter: { client: localStorage.client } })
      .then(function (response) {
        const data = response.data.entries[0];
        localStorage.setItem('cltObj', JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });

    getInvoices();
  }, []);

  const getInvoices = () => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`, {})
      .then(function (response) {
        const invoicesByYear = {};
        const invoicesByYearP = {};
        let totalPyed = 0;
        let totalUnpayed = 0;
        let totalPyedWithoutNumber = 0;
        let totalUnpayedWithoutNumber = 0;
        const allInvoices = response.data.entries.reverse();

        const data = allInvoices.filter(item => (item['payed'] === false || item['payed'] === ''));

        setInvoices(data);
        setAllInvoices(allInvoices);
        let total = 0;
        let totalWithoutNumber = 0;

        allInvoices.forEach((invoice) => {
          const dateObject = new Date(invoice.date);
          const year = dateObject.getUTCFullYear();
          const monthUTC = dateObject.getUTCMonth();

          if ((invoice.deleted === false || !invoice.deleted) && invoice.invoice) {
            total = total + invoice.total;
            if (invoice.payed) totalPyed = totalPyed + invoice.total;
            else totalUnpayed = totalUnpayed + invoice.total;

            if (invoicesByYear[year]) {
              invoicesByYear[year][monthUTC] = invoicesByYear[year][monthUTC] + invoice.total || 0;
            }
            else {
              invoicesByYear[year] = { [monthUTC]: '' };
              invoicesByYear[year][monthUTC] = invoice.total
            }
          }
          if ((invoice.deleted === false || !invoice.deleted) && !invoice.invoice) {
            totalWithoutNumber = totalWithoutNumber + invoice.total;

            if (invoice.payed) totalPyedWithoutNumber = totalPyedWithoutNumber + invoice.total;
            else totalUnpayedWithoutNumber = totalUnpayedWithoutNumber + invoice.total;

            if (invoicesByYearP[year]) {
              invoicesByYearP[year][monthUTC] = invoicesByYearP[year][monthUTC] + invoice.total || 0;
            }
            else {
              invoicesByYearP[year] = { [monthUTC]: '' };
              invoicesByYearP[year][monthUTC] = invoice.total
            }
          }
        });

        setTotalAllInvoices(total);
        setTotalValuesByYear(invoicesByYear);
        setTotalValuesByYearP(invoicesByYearP);
        setTotalAllInvoicesWithoutNumber(totalWithoutNumber)
        setAllInvoicesPayed(totalPyed);
        setAllInvoicesUnpayed(totalUnpayed);
        setTotalAllInvoicesPayedWithoutNumber(totalPyedWithoutNumber);
        setTotalAllInvoicesUnpayedWithoutNumber(totalUnpayedWithoutNumber);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const grow = calculatePercentualGrow(totalValuesByYear)

  return (
    <div style={{ marginTop: '25px', paddingBottom: '95px' }}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                  <div style={{ padding: 20 }}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>Total</Typography>
                    <Typography component="p" variant="h4">
                      <FormattedNumber value={totalAllInvoices} style="currency" currency="EUR" />
                    </Typography>
                    <Typography component="p" variant="p">
                      Pas de n. facture <b><FormattedNumber value={totalAllInvoicesWithoutNumber} style="currency" currency="EUR" /></b>
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                  <div style={{ padding: 20 }}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>Total payé</Typography>
                    <Typography component="p" variant="h4" style={{ color: '#0ad90a' }}>
                      <FormattedNumber value={allInvoicesPayed} style="currency" currency="EUR" />
                    </Typography>
                    <Typography component="p" variant="p" style={{ color: '#0ad90a' }}>
                      Pas de n. facture <b><FormattedNumber value={totalAllInvoicesPayedWithoutNumber} style="currency" currency="EUR" /></b>
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                  <div style={{ padding: 20 }}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>Total impayé</Typography>
                    <Typography component="p" variant="h4" style={{ color: 'red' }}>
                      <FormattedNumber value={allInvoicesUnpayed} style="currency" currency="EUR" />
                    </Typography>
                    <Typography component="p" variant="p" style={{ color: 'red' }}>
                      Pas de n. facture <b><FormattedNumber value={totalAllInvoicesUnpayedWithoutNumber} style="currency" currency="EUR" /></b>
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <div style={{ marginTop: '25px' }}>
        <Container maxWidth="lg" className={classes.container}>
          <YearChart values={totalValuesByYear} valuesP={totalValuesByYearP} />
          <Grid container spacing={3}>
            {Object.keys(totalValuesByYear).reverse().map(year => {
              const sum = Object.values(totalValuesByYear?.[year] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              const sumP = Object.values(totalValuesByYearP?.[year] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              const total = sum + sumP;

              return (<Grid item xs={12} md={3} lg={3}>
                <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                  <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography component="h2" variant="h6" color="primary" gutterBottom>{year}</Typography>
                      {grow[year] && (<Typography component="p" variant="p" style={{ color: grow[year] > 0 ? '#0ad90a' : 'red' }} gutterBottom><span style={{ color: '#888', fontSize: '12px' }}>l'année précédente</span> {(grow[year]).toFixed(2)}%</Typography>)}
                    </div>
                    <Typography component="p" variant="h4" style={{ color: grow[year] > 0 ? '#0ad90a' : 'red' }}>
                      <FormattedNumber value={total} style="currency" currency="EUR" />
                    </Typography>
                    <Typography component="p" variant="p" style={{ color: '#999' }}>
                      N. facture <b><FormattedNumber value={sum} style="currency" currency="EUR" /></b>
                    </Typography>
                    <Typography component="p" variant="p" style={{ color: '#999' }}>
                      Pas de n. facture <b><FormattedNumber value={sumP} style="currency" currency="EUR" /></b>
                    </Typography>
                  </div>
                </Paper>
              </Grid>)
            })}
          </Grid>
        </Container>
      </div>
      <Divider />
      <div style={{ marginTop: '25px' }}>
        <Container maxWidth="lg" className={classes.container}>
          <div>
            <Radio
              checked={period === 'month'}
              onChange={e =>
                setPeriod(e.target.value)
              }
              value="month"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
            />
            Mensuel
            <Radio
              checked={period === 'quarterly'}
              onChange={e =>
                setPeriod(e.target.value)
              }
              value="quarterly"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'B' }}
            />
            Trimestriel
          </div>
          {period === 'month' ? (
            <>
              <MonthlyChart values={totalValuesByYear} valuesP={totalValuesByYearP} />
              <Grid container spacing={3}>
                {MONTHS.map((month, i) => {
                  const sumResult = sumObjects(totalValuesByYear, totalValuesByYearP);

                  return (
                    <>
                      <Grid item xs={12} md={2} lg={2}>
                        <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                          <div style={{ padding: 20 }}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>{month}</Typography>
                            <Typography component="p" variant="p">
                              {Object.keys(sumResult).length === 0 && (<FormattedNumber value={0} style="currency" currency="EUR" />)}
                              {Object.keys(sumResult).reverse().map(year => {
                                let color = '#000';
                                if (sumResult[year]?.[i] < sumResult[year - 1]?.[i]) color = 'red'
                                else if (sumResult[year]?.[i] > sumResult[year - 1]?.[i]) color = '#0ad90a'

                                return (
                                  <div key={year} style={{ display: 'flex' }}>
                                    <div>
                                      <strong>{year}</strong>:
                                    </div>
                                    <p style={{ color, paddingLeft: '7px' }}>
                                      <FormattedNumber value={sumResult[year]?.[i] || 0} style="currency" currency="EUR" />
                                    </p>
                                    {/* {
                                  !!totalValuesByYear[year]?.[i] && (<>
                                    {color === 'red' ? <KeyboardArrowDownIcon style={{ color }} /> : <KeyboardArrowUpIcon style={{ color }} />}
                                  </>)
                                } */}
                                  </div>
                                )
                              })}

                            </Typography>
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  )
                })}
              </Grid>
            </>
          ) : (
            <>
              <QuarterlyChart values={totalValuesByYear} valuesP={totalValuesByYearP} />
              <Grid container spacing={3}>
                {QUARTERS.map((quarter, i) => {
                  const sumResult = sumObjects(totalValuesByYear, totalValuesByYearP);

                  return (
                    <Grid item xs={12} md={3} lg={3}>
                      <Paper className={fixedHeightPaper} style={{ marginBottom: '15px' }}>
                        <div style={{ padding: 20 }}>
                          <Typography component="h2" variant="h6" color="primary" gutterBottom>{quarter}</Typography>
                          <Typography component="p" variant="p">
                            {Object.keys(sumResult).length === 0 && (<FormattedNumber value={0} style="currency" currency="EUR" />)}
                            {Object.keys(sumResult).reverse().map(year => {
                              let quartertotal = 0;
                              let quartertotalLastYear = 0;
                              if (i === 0) {
                                quartertotal = (sumResult[year]?.[0] || 0) + (sumResult[year]?.[1] || 0) + (sumResult[year]?.[2] || 0)
                                quartertotalLastYear = (sumResult[year - 1]?.[0] || 0) + (sumResult[year - 1]?.[1] || 0) + (sumResult[year - 1]?.[2] || 0)
                              } else if (i === 1) {
                                quartertotal = (sumResult[year]?.[4] || 0) + (sumResult[year]?.[5] || 0) + (sumResult[year]?.[6] || 0)
                                quartertotalLastYear = (sumResult[year - 1]?.[4] || 0) + (sumResult[year - 1]?.[5] || 0) + (sumResult[year - 1]?.[6] || 0)
                              } else if (i === 2) {
                                quartertotal = (sumResult[year]?.[7] || 0) + (sumResult[year]?.[8] || 0) + (sumResult[year]?.[9] || 0)
                                quartertotalLastYear = (sumResult[year - 1]?.[7] || 0) + (sumResult[year - 1]?.[8] || 0) + (sumResult[year - 1]?.[9] || 0)
                              } else if (i === 3) {
                                quartertotal = (sumResult[year]?.[10] || 0) + (sumResult[year]?.[11] || 0) + (sumResult[year]?.[12] || 0)
                                quartertotalLastYear = (sumResult[year - 1]?.[10] || 0) + (sumResult[year - 1]?.[11] || 0) + (sumResult[year - 1]?.[12] || 0)
                              }

                              let color = '#000';
                              if ((quartertotal < quartertotalLastYear) && quartertotal > 0) color = 'red'
                              else color = '#0ad90a'

                              return (
                                <div key={year} style={{ display: 'flex' }}>
                                  <div>
                                    <strong>{year}</strong>:
                                  </div>
                                  <p style={{ color, paddingLeft: '7px' }}>
                                    {quartertotal > 0 ? (<FormattedNumber value={quartertotal} style="currency" currency="EUR" />) : '-'}
                                  </p>
                                  {
                                    quartertotal < quartertotalLastYear && quartertotal > 0 && (<>
                                      {color === 'red' ? <KeyboardArrowDownIcon style={{ color }} /> : <KeyboardArrowUpIcon style={{ color }} />}
                                    </>)
                                  }
                                </div>
                              )
                            })}
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  )
                })}
              </Grid>
            </>
          )}
        </Container>
      </div >

    </div >
  );
}

export default injectIntl(Home);
