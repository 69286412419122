import React, { useEffect, useState } from "react";
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useParams } from "react-router-dom";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import ProviderPdf from "./reports/ProviderPdf";
import { injectIntl } from 'react-intl'
import { Container } from "@material-ui/core";
import { apiUrl } from "./vars";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  padding: {
    paddingTop: '20px'
  },
  right: {
    textAlign: 'right',
    marginTop: '20px',
    display: 'inline-block',
  }
}));

const ProviderDetailBulk = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [invoice, setInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}_providers?token=${localStorage.token}`, {})
      .then(function (response) {
        console.log(response);
        let ids = JSON.parse(localStorage.getItem('providers-bulk'));

        const { entries } = response.data;
        let array = [];
        ids.map(i => {
          array.push(entries.find(item => (item._id === i)))
        });

        setInvoice(array);
        setIsLoading(false);
      })
      .catch(function (error) {
        // localStorage.clear();
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const translations = {
    description: 'Description',
    unite: 'Unité',
    quantite: 'Quantité',
    coutUnitaire: 'Coût unitaire',
    montant: 'Montant',
    type: 'Facture',
  }

  return (
    <div style={{ marginTop: '25px' }}>
      <Container fixed style={{ textAlign: 'center', marginBottom: 20 }}>
        <PDFDownloadLink document={<ProviderPdf invoice={invoice} translations={translations} intl={props.intl} />} fileName={`${invoice.length === 1 ? invoice[0].invoiceNumber : 'Despenses'}.pdf`}>
          {({ blob, url, loading, error }) => (loading ? 'Chargement du document ...' : <Button variant="contained" color="primary">Télécharger le document PDF</Button>)}
        </PDFDownloadLink>
      </Container>
      {invoice.length === 1 && (
        <PDFViewer width="950" height="1200" className="pdf-viewer" >
          <ProviderPdf invoice={invoice} translations={translations} intl={props.intl} />
        </PDFViewer>
      )}
    </div>
  );
}

export default injectIntl(ProviderDetailBulk);
