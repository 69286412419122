import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import GTranslateIcon from '@material-ui/icons/GTranslate';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const TranslatePage = ({ changeTranslateText, i }) => {
  const [valuePt, setValuePt] = useState('')
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValuePt(e.target.value);
  };

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const handleClickTranslate = async () => {
    if (valuePt) {
      const url = `/translate.php`;
      const formData = new FormData();
      formData.append('text', valuePt);

      axios.post(url,
        formData,
      )
        .then(function (response) {
          changeTranslateText(decodeHtml(response.data.translation), i);
          setOpen(false);
          setValuePt('');
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return (
    <div>
      <button type="button" onClick={handleOpen} style={{ color: '#888888' }}>
        <GTranslateIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={3} style={{ width: '500px' }}>
              <Grid item xs={12}>
                <TextField
                  id="standard-multiline-flexible"
                  label="Escreva o texto que pretende traduzir para francês"
                  multiline
                  fullWidth
                  rowsMax={4}
                  value={valuePt}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="default" onClick={() => setOpen(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" onClick={handleClickTranslate}>
                  Traduzir
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default TranslatePage;
