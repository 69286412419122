import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },

    yAxes: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        callback: value => new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,

    },
  },
};

const colors = ['#ddf065', '#9ff587', '#65f0e7', '#6578f0', '#fa7ffa', '#f78b90']

const YearChart = ({ values, valuesP }) => {


  const dataValues = Object.keys(values).reverse().map(year => {
    return Object.values(values?.[year] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  })

  const dataValuesP = Object.keys(valuesP).reverse().map(year => {
    return Object.values(valuesP?.[year] || {})?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  })

  const data = {
    labels: Object.keys(values).reverse(),
    datasets: [{
      type: 'bar',
      label: 'Avec n. facture',
      data: dataValues,
      backgroundColor: '#52c4bd',
    },
    {
      type: 'bar',
      label: 'Pas de n. facture',
      data: dataValuesP,
      backgroundColor: '#65f0e7',
    }],
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Bar options={options} data={data} height={75} />
    </div>
  );
}

export default YearChart