import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import moment from 'moment';
import Moment from 'react-moment';
import { FormattedNumber } from 'react-intl'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { apiUrl } from './vars';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  { id: 'invoice', numeric: false, disablePadding: true, label: 'Facture N.' },
  { id: 'clientName', numeric: false, disablePadding: false, label: 'Nom du client' },
  { id: 'clientCompany', numeric: false, disablePadding: false, label: "Nom de l'entreprise" },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'payed', numeric: false, disablePadding: false, label: 'Payé' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'total', numeric: false, disablePadding: false, label: 'Montant' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const [yearFilter, setYearFilter] = useState(null);
  const [payedFilter, setPayedFilter] = useState(null);

  const pdf = () => {
    localStorage.setItem('bulk', JSON.stringify(props.selected))
    window.location.href = '/invoices/detail/bulk';
  }

  const refresh = () => window.location.reload();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Imprimer des factures
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="PDF">
          <IconButton aria-label="PDF" onClick={pdf}>
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', height: '30px' }}>
            <ButtonGroup variant="text" aria-label="text button group">
              <Button
                variant={yearFilter === "2021" ? "contained" : ""}
                onClick={() => {
                  setYearFilter('2021')
                  props.handleChangeFilter('2021', payedFilter);
                }
                }>2021</Button>
              <Button
                variant={yearFilter === "2022" ? "contained" : ""}
                onClick={() => {
                  setYearFilter('2022')
                  props.handleChangeFilter('2022', payedFilter);
                }
                }>2022</Button>
              <Button
                variant={yearFilter === "2023" ? "contained" : ""}
                onClick={() => {
                  setYearFilter('2023')
                  props.handleChangeFilter('2023', payedFilter);
                }
                }>2023</Button>
            </ButtonGroup>
            <ButtonGroup variant="text" aria-label="text button group">
              <Button
                variant={payedFilter === "not-payed" ? "contained" : ""}
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  setPayedFilter('not-payed');
                  props.handleChangeFilter(yearFilter, 'not-payed');
                }
                }>Non Payé</Button>
              <Button
                variant={payedFilter === "payed" ? "contained" : ""}
                onClick={() => {
                  setPayedFilter('payed')
                  props.handleChangeFilter(yearFilter, 'payed');
                }
                }>Payé</Button>
            </ButtonGroup>
            <Tooltip title="Toute">
              <Button
                size="small"
                onClick={() => {
                  setYearFilter(null);
                  setPayedFilter(null);
                  props.handleChangeShowAll();
                }
                }>Toute</Button>
            </Tooltip>
          </div>
          <Tooltip title="Refresh">
            <IconButton aria-label="refresh" onClick={refresh}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const BulkInvoice = () => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`, {})
      .then(function (response) {

        setRows(response.data.entries);
        setFilterRows(response.data.entries);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filterRows.map(n => n._id);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filterRows.length - page * rowsPerPage);

  const handleChangeFilter = (yearFilter, payedFilter) => {
    let filter = [];

    if (yearFilter) {
      filter = rows.filter(row => moment(row.date).isBetween(`${yearFilter}-01-01`, `${yearFilter}-12-31`))
    }
    // let matchSet = new Set(filter);

    if (payedFilter) {
      if (!yearFilter) filter = [...rows]
      if (payedFilter === 'payed') {
        let matchSet = new Set(filter);
        const data = [];
        matchSet.forEach((row) => {
          if (row.payed === 1 || row.payed === true) {
            data.push(row);
          }
        });

        filter = [...data]
      } else {
        let matchSet = new Set(filter);
        const data = [];
        matchSet.forEach((row) => {
          if (row.payed === false || row.payed === '') {
            data.push(row);
          }
        });

        filter = [...data]
      }
    }


    setFilterRows(filter);
  }

  const handleChangeShowAll = () => {
    setFilterRows(rows);
  }

  // console.log('rows', filterRows);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          handleChangeFilter={handleChangeFilter}
          handleChangeShowAll={handleChangeShowAll}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filterRows.length}
            />
            <TableBody>
              {filterRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const invoice = `enhanced-table-checkbox-${index}`;

                if (!row.deleted) {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': invoice }}
                        />
                      </TableCell>
                      <TableCell component="th" id={invoice} scope="row" padding="none">{row.invoice}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.clientCompany}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.payed ? <Tooltip title="Non payé" placement="top">
                          <CheckCircleIcon />
                        </Tooltip> : <Tooltip title="Marquer comme payé" placement="top">
                          <RadioButtonUncheckedIcon />
                        </Tooltip>}
                      </TableCell>
                      <TableCell>
                        <Moment format="DD-MM-YYYY" style={{ whiteSpace: 'nowrap' }}>
                          {row.date}
                        </Moment>
                      </TableCell>
                      <TableCell>
                        <FormattedNumber value={row.total} style="currency" currency="EUR" />
                      </TableCell>
                    </TableRow>
                  );
                }
                return '';
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default BulkInvoice;
