import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

const PdfDownload = ({ invoice }) => {
  const cltObj = JSON.parse(localStorage.cltObj || '{}');

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={cltObj.imageLogo} />
        <Text style={styles.reportTitle}>
          {cltObj.email}
        </Text>
        <Text style={styles.reportTitle}>
          {cltObj.phone}
        </Text>
        <Text style={styles.reportTitle}>
          {cltObj.address}
        </Text>
        <Text style={styles.reportTitle}>
          {cltObj.zip}
        </Text>
        <Text style={styles.reportTitle}>
          {JSON.stringify(invoice)}
        </Text>
      </Page>
    </Document>
  );
};

export default PdfDownload;