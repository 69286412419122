import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TextField from '@material-ui/core/TextField';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { sortBy } from 'lodash';
import { apiUrl } from './vars';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const ClientsTable = ({ clients, handleSelectCliente }) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [clientRows, setClientRows] = useState(clients || []);
  const [sortClientsBy, setSortClientsBy] = useState('clientNumber');

  useEffect(() => {
    if (clientRows.length === 0) {
      axios.post(`${apiUrl}/collections/get/${localStorage.client}_clientes?token=${localStorage.token}`, {})
        .then(function (response) {
          setClientRows(response.data.entries.reverse());
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, clientRows.length - page * rowsPerPage);

  useEffect(() => {
    const sortedClients = sortBy(clients, [(o) => { return o[sortClientsBy]; }]);
    setClientRows(sortedClients);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 6));
    setPage(0);
  };

  const handleSearch = (e) => {
    setPage(0);
    const { name, value } = e.target;
    let data;
    if (e.target.name === 'clientNumber') {
      data = clients.filter((row) => (JSON.stringify(row[name]) === value));
    } else {
      data = clients.filter((row) => (JSON.stringify(row[name]).toLowerCase().includes(value.toLowerCase())));
    }
    if (value) setClientRows(data);
    else setClientRows(clients);
  }

  const createSortHandler = (field) => {
    const sortedClients = sortBy(clientRows, [(o) => { return o[field]; }]);
    setClientRows(sortedClients);
    setSortClientsBy(field);
  }

  const handleSelectEditCliente = (event, row) => {
    if (clients) handleSelectCliente(event, row);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell sortDirection="id" style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortClientsBy === 'clientNumber'}
                  direction="desc"
                  onClick={() => createSortHandler('clientNumber')}
                >
                  N.º Client
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortClientsBy === 'clientName'}
                  direction="desc"
                  onClick={() => createSortHandler('clientName')}
                >
                  Nom Client
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortClientsBy === 'clientCompany'}
                  direction="desc"
                  onClick={() => createSortHandler('clientCompany')}
                >
                  Nom société
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortClientsBy === 'address'}
                  direction="desc"
                  onClick={() => createSortHandler('address')}
                >
                  Adresse
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortClientsBy === 'zip'}
                  direction="desc"
                  onClick={() => createSortHandler('zip')}
                >
                  Code postal
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortClientsBy === 'phone'}
                  direction="desc"
                  onClick={() => createSortHandler('phone')}
                >
                  Téléphone
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortClientsBy === 'email'}
                  direction="desc"
                  onClick={() => createSortHandler('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <TextField
                  name="clientNumber"
                  onChange={handleSearch}
                  fullWidth
                  id="clientNumber"
                  label="N.º Client"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <TextField
                  name="clientName"
                  onChange={handleSearch}
                  fullWidth
                  id="clientName"
                  label="Nom Client"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="clientCompany"
                  onChange={handleSearch}
                  fullWidth
                  id="clientCompany"
                  label="Nom société"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="address"
                  onChange={handleSearch}
                  fullWidth
                  id="address"
                  label="Adresse"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="zip"
                  onChange={handleSearch}
                  fullWidth
                  id="zip"
                  label="Code postal"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="phone"
                  onChange={handleSearch}
                  fullWidth
                  id="phone"
                  label="Téléphone"
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="email"
                  onChange={handleSearch}
                  fullWidth
                  id="email"
                  label="Email"
                />
              </TableCell>
            </TableRow>
            {(rowsPerPage > 0
              ? clientRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : clientRows
            ).map((row) => (
              <TableRow key={row._id} onClick={(event) => handleSelectEditCliente(event, row)}>
                <TableCell component="th" scope="row">
                  {row.clientNumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.clientName}
                </TableCell>
                <TableCell>
                  {row.clientCompany}
                </TableCell>
                <TableCell>
                  {row.address}
                </TableCell>
                <TableCell>
                  {row.zip}
                </TableCell>
                <TableCell>
                  {row.phone}
                </TableCell>
                <TableCell>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={clientRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ClientsTable;
