import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedNumber } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import { apiUrl } from './vars';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const handlePay = (status) => {
    const data = { ...row }
    data.payed = status;
    console.log('data', data);
    axios.post(`${apiUrl}/collections/save/${localStorage.client}_quote?token=${localStorage.token}`, { data })
      .then(function (response) {
        props.getInvoices();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleSend = (status) => {
    const data = { ...row }
    data.send = status;
    console.log('data', data);
    axios.post(`${apiUrl}/collections/save/${localStorage.client}_quote?token=${localStorage.token}`, { data })
      .then(function (response) {
        props.getInvoices();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let history = useHistory();
  const editRow = (id) => {
    history.push(`/quote/edit/${id}`);
  }

  const viewInvoice = (id) => {
    history.push(`/quote/detail/${id}`);
  };

  const createInvoice = (row) => {
    localStorage.setItem('createInvoice', JSON.stringify(row));
    history.push(`/invoices/edit/${row._id}`);
  };

  return (
    <>
      <TableRow className={classes.root} style={{ background: row.payed ? '#ccf9cc' : '' }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ whiteSpace: 'nowrap' }}>
          {row.invoice}
        </TableCell>
        <TableCell align="left">{row.clientName}</TableCell>
        <TableCell align="left">{row.clientCompany}</TableCell>
        <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{row.email}</TableCell>
        <TableCell align="left">
          <Moment format="DD-MM-YYYY" style={{ whiteSpace: 'nowrap' }}>
            {row.date}
          </Moment>
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          <FormattedNumber value={row.total} style="currency" currency="EUR" />
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {row.payed ? <Tooltip title="Marquer comme non payé" placement="top">
            <IconButton variant="contained" onClick={() => handlePay(false)}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip> : <Tooltip title="Marquer comme payé" placement="top">
            <IconButton variant="contained" color="secondary" onClick={() => handlePay(true)}>
              <RadioButtonUncheckedIcon />
            </IconButton>
          </Tooltip>}
          {row.send ? <Tooltip title="Marquer comme non envoi" placement="top">
            <IconButton variant="contained" onClick={() => handleSend(false)}>
              <EmailIcon />
            </IconButton>
          </Tooltip> : <Tooltip title="Marquer comme envoi" placement="top">
            <IconButton variant="contained" color="secondary" onClick={() => handleSend(true)}>
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>}
          <Tooltip title="Modifier le devis" placement="top">
            <IconButton variant="contained" onClick={() => editRow(row._id)}><EditIcon /></IconButton >
          </Tooltip>
          <Tooltip title="Afficher le devis PDF" placement="top">
            <IconButton variant="contained" onClick={() => viewInvoice(row._id)}><PictureAsPdfIcon /></IconButton >
          </Tooltip>
          <Tooltip title="Creé un facture" placement="top">
            <IconButton variant="contained" onClick={() => createInvoice(row)}><DescriptionIcon /></IconButton >
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: row.payed ? '#e1ffe1' : '#f5f5f5' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="p" gutterBottom component="div">
                {`Devis - ${row.invoice}`}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">Description</TableCell>
                    <TableCell>Unité</TableCell>
                    <TableCell align="right">Quantité </TableCell>
                    <TableCell align="right">Coût unitaire</TableCell>
                    <TableCell align="right">Montant (€)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lines.map((historyRow, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {historyRow.description}
                      </TableCell>
                      <TableCell>{historyRow.unit}</TableCell>
                      <TableCell align="right">{historyRow.quantity}</TableCell>
                      <TableCell align="right">
                        {historyRow.unitPrice && (
                          <FormattedNumber value={historyRow.unitPrice} style="currency" currency="EUR" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.total && (
                          <FormattedNumber value={historyRow.total} style="currency" currency="EUR" />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="right" colSpan={4}>
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell align="right" colSpan={1}>
                      <strong><FormattedNumber value={row.total} style="currency" currency="EUR" /></strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {

};

const Quote = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [allInvoicesPayed, setAllInvoicesPayed] = useState([]);
  const [allInvoicesUnpayed, setAllInvoicesUnpayed] = useState([]);
  const [totalAllInvoices, setTotalAllInvoices] = React.useState(0);
  const [searchForm, setSearchForm] = useState({
    invoice: '',
    clientName: '',
    payed: '',
  });

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/clientes?token=${localStorage.token}`, { filter: { client: localStorage.client } })
      .then(function (response) {
        const data = response.data.entries[0];
        localStorage.setItem('cltObj', JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });

    getInvoices();
  }, []);

  const getInvoices = () => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}_quote?token=${localStorage.token}`, {})
      .then(function (response) {
        let total = 0;
        let totalPyed = 0;
        let totalUnpayed = 0;
        setInvoices(response.data.entries);
        setAllInvoices(response.data.entries);
        response.data.entries.map((invoice) => {
          total = total + invoice.total;
          if (invoice.payed) totalPyed = totalPyed + invoice.total;
          else totalUnpayed = totalUnpayed + invoice.total;
        });
        setTotalAllInvoices(total);
        setAllInvoicesPayed(totalPyed);
        setAllInvoicesUnpayed(totalUnpayed);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchForm((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'payed') {
      if (value !== 'all') {
        const data = allInvoices.filter(item => (item[name] === value));
        setInvoices(data);
      } else {
        setInvoices(allInvoices);
      };
    } else {
      const data = allInvoices.filter((row) => (JSON.stringify(row[name]).toLowerCase().includes(value.toLowerCase())));
      setInvoices(data);
    }
  };

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div style={{ marginTop: '25px', paddingBottom: '95px' }}>
      <Container fixed>
        <Paper style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Typography variant="h5">
            Recherche de devis
          </Typography>
          <Grid container spacing={3} style={{ marginBottom: '30px' }}>
            <Grid item xs={12} md={2} sm={3}>
              <TextField
                id="invoice"
                fullWidth
                label="Devis n.º"
                onChange={handleSearch}
                name="invoice"
              />
            </Grid>
            <Grid item xs={12} md={8} sm={6}>
              <TextField
                id="clientName"
                fullWidth
                onChange={handleSearch}
                label="Nom du client"
                name="clientName"
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3}>
              <FormControl className={classes.formControl}>
                <InputLabel id="payed-select-label">Etat</InputLabel>
                <Select
                  labelId="payed-select-label"
                  id="payed"
                  name="payed"
                  value={searchForm.payed}
                  onChange={handleSearch}
                >
                  <MenuItem value="all">Toute</MenuItem>
                  <MenuItem value={true}>Payé</MenuItem>
                  <MenuItem value={false}>Non payé</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <div style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 999 }}>
        <Link to="/quote/add">
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell width="120px">Devis N.</TableCell>
              <TableCell>Nom du client</TableCell>
              <TableCell>Nom de l'entreprise</TableCell>
              <TableCell>Email</TableCell>
              <TableCell width="120px">Date</TableCell>
              <TableCell align="right">Montant</TableCell>
              <TableCell width="180px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((row) => (
              <Row key={row._id} row={row} getInvoices={getInvoices} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container fixed>
        <Grid container spacing={3} style={{ marginTop: 20, fontSize: 12 }}>
          <Grid item xs={6} md={2} sm={2} style={{ color: 'red' }} align="right">
            <strong>Total impayé</strong>
          </Grid>
          <Grid item xs={6} md={2} sm={2} style={{ color: 'red' }}>
            <strong><FormattedNumber value={allInvoicesUnpayed} style="currency" currency="EUR" /></strong>
          </Grid>
          <Grid item xs={6} md={2} sm={2} style={{ color: 'green' }} align="right">
            <strong>Total payé</strong>
          </Grid>
          <Grid item xs={6} md={2} sm={2} style={{ color: 'green' }}>
            <strong><FormattedNumber value={allInvoicesPayed} style="currency" currency="EUR" /></strong>
          </Grid>
          <Grid item xs={6} md={2} sm={2} align="right">
            <strong>Total</strong>
          </Grid>
          <Grid item xs={6} md={2} sm={2}>
            <strong><FormattedNumber value={totalAllInvoices} style="currency" currency="EUR" /></strong>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Quote;
