import React from 'react';
import ReactDOM from 'react-dom';
import './less/index.less';
import App from './App';
import { IntlProvider } from 'react-intl'
// import registerServiceWorker from './registerServiceWorker';

const messagesInFrench = {
  myMessage: "Aujourd'hui, c'est le {ts, date, ::yyyyMMdd}",
}

ReactDOM.render(
  <IntlProvider messages={messagesInFrench} locale="pt" defaultLocale="pt">
    <App />
  </IntlProvider>, document.getElementById('root'));
// registerServiceWorker();
