import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
});

const LeftMenu = ({ toggleDrawer, state }) => {
  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={toggleDrawer(false, '/')}>
          <ListItemIcon><ViewListIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/invoices')}>
          <ListItemIcon><ViewListIcon /></ListItemIcon>
          <ListItemText primary="Factures" />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/invoice/bulk')}>
          <ListItemIcon><PrintIcon /></ListItemIcon>
          <ListItemText primary={'Imprimer des factures'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/invoices/add')}>
          <ListItemIcon><AddCircleIcon /></ListItemIcon>
          <ListItemText primary={'Créer une facture'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/quote/list')}>
          <ListItemIcon><ListAltIcon /></ListItemIcon>
          <ListItemText primary={'Devis'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/quote/add')}>
          <ListItemIcon><AddCircleIcon /></ListItemIcon>
          <ListItemText primary={'Créer un devis'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/clients/list')}>
          <ListItemIcon><RecentActorsIcon /></ListItemIcon>
          <ListItemText primary={'Liste de clients'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/providers/list')}>
          <ListItemIcon><AddCircleIcon /></ListItemIcon>
          <ListItemText primary={'Ajouter une dépense'} />
        </ListItem>
        <ListItem button onClick={toggleDrawer(false, '/providers/bulk')}>
          <ListItemIcon><PrintIcon /></ListItemIcon>
          <ListItemText primary={'Imprimer des dépenses'} />
        </ListItem>
      </List>
    </div >
  );

  return (
    <div>
      <Drawer anchor="left" open={state} onClose={toggleDrawer(false, '')}>
        {list('left')}
      </Drawer>
    </div>
  );
}

export default LeftMenu;