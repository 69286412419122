import React from 'react';
import PropTypes from 'prop-types';

const NotFound = props => {
  return (
    <div>
      Not found
    </div>
  );
};

NotFound.propTypes = {
  
};

export default NotFound;