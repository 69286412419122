import React from 'react';
import { Page, Document, Image, StyleSheet, Text } from '@react-pdf/renderer';
import ProviderHeader from './ProviderHeader'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: '100%',
    height: '380px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 70,
    backgroundColor: '#ffffff',
    transform: 'rotate(90deg)',
  }
});

const renderPdf = (invoice, translations, intl) => {
  return invoice.map(row => {
    return (
      <Page size="A4" style={styles.page} wrap key={row._id}>
        <ProviderHeader row={row} translations={translations} intl={intl} fixed />
        {row.image ? (
          <Image style={styles.logo} src={`/upload/${row.image}`} />
        ) : <Text style={styles.colLabelGray}>No image</Text>}
      </Page>
    );
  });
}

const ProviderPdf = ({ invoice, translations, intl }) => {
  return (
    <Document title={invoice.length === 1 ? invoice[0].invoiceNumber : 'Fature'}>
      {renderPdf(invoice, translations, intl)}
    </Document>
  );
};

export default ProviderPdf;