import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const borderColor = color
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: color,
    borderBottomWidth: 1,
    alignItems: 'center',
    fontStyle: 'bold',
    minHeight: 24,
    color: '#888',
  },
  description: {
    width: '50%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    lineHeight: '0,7',
    height: '100%',
  },
  unit: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    lineHeight: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  unitPrice: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  quantity: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  amount: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  textCenter: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    height: 15,
  },
  textRight: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'right',
    height: 15,
  },
});


const InvoiceTableRow = ({ items, intl }) => {
  const rows = items.map((item, i) =>
    <View style={styles.row} key={i.toString()} wrap={false}>
      <Text style={styles.description} wrap={true}>{item.description || ' '}</Text>
      <View style={styles.unit}>
        <Text style={styles.textCenter}>{item.unit || ' '}</Text>
      </View>
      <View style={styles.unitPrice} break>
        <Text style={styles.textRight}>{item.quantity || ' '}</Text>
      </View>
      <View style={styles.quantity}>
        <Text style={styles.textRight}>{item.unitPrice ? intl.formatNumber(item.unitPrice, { style: 'currency', currency: 'EUR' }) : ' '}</Text>
      </View>
      <View style={styles.amount}>
        <Text style={styles.textRight}>{item.total ? intl.formatNumber(item.total, { style: 'currency', currency: 'EUR' }) : ' '}</Text>
      </View>
    </View>
  )
  return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow