import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { apiUrl } from './vars';

const AddClients = () => {
  const [clients, setClients] = useState('');
  const [count, setCount] = useState(0);

  const handleSubmit = () => {
    const clientsObject = JSON.parse(clients);
    clientsObject.map(data => {
      axios.post(`${apiUrl}/collections/save/${localStorage.client}_clientes?token=${localStorage.token}`, {
        data
      })
        .then(function (response) {

          setCount(count + 1)
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }

  const handleChange = (e) => {
    setClients(e.target.value)
  }

  return (
    <Container fixed>
      <TextField
        id="clients"
        label="clients"
        //  label="JSON"
        rowsMax={10}
        fullWidth
        value={clients}
        onChange={handleChange}
      />
      <br />
      <br />
      <Button
        variant="contained"
        onClick={handleSubmit}
      >
        Import Clients
      </Button>
      <br />
      <br />
      {`Saved: ${count}`}
    </Container>
  );
};

export default AddClients;