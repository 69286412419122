import 'date-fns';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NotificationManager } from 'react-notifications';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import ClientsTable from './ClientsTable';
import AddItemContainer from './AddItemContainer';
import { useParams, useHistory } from "react-router-dom";
import SaveIcon from '@material-ui/icons/Save';
import { apiUrl } from './vars';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  padding: {
    paddingTop: '20px'
  },
  right: {
    textAlign: 'right',
    marginTop: '20px',
    display: 'inline-block',
  }
}));

const AddQuote = () => {
  const [form, setForm] = useState({
    _id: null,
    date: null,
    invoice: '',
    destination: '',
    clientNumber: '',
    company: '',
    address: '',
    zip: '',
    phone: '',
    email: '',
  });

  const { id } = useParams();
  let history = useHistory();

  const [error, setError] = useState({
    destination: false,
    address: false,
    phone: false,
    email: false,
  });
  const [lines, setLines] = useState([{
    description: "",
    unit: "",
    quantity: "",
    unitPrice: "",
    total: ""
  }]);
  const [myData, setMyData] = useState({});
  const [clients, setClients] = useState([]);
  const [state, setState] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [disbledSaveClient, setDisbledSaveClient] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalInvoice, setTotalInvoice] = React.useState(0);
  const [invoicesList, setInvoicesList] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [isChangedInvoiceNumber, setIsChangedInvoiceNumber] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const pad = (value, size) => {
    var s = String(value);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/clientes?token=${localStorage.token}`, { filter: { client: localStorage.client } })
      .then(function (response) {
        const data = response.data.entries[0];
        setMyData(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.post(`${apiUrl}/collections/get/${localStorage.client}_clientes?token=${localStorage.token}`, {})
      .then(function (response) {
        setClients(response.data.entries.reverse());
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.post(`${apiUrl}/collections/get/${localStorage.client}_quote?token=${localStorage.token}`, {})
      .then(function (response) {
        // console.log(response);
        setInvoicesList(response.data.entries);
      })
      .catch(function (error) {
        console.log(error);
      });

    const date = new Date();
    setForm((prevState) => ({ ...prevState, date }));
    setForm((prevState) => ({ ...prevState, invoice: `DE${date.getFullYear()}${pad(date.getMonth() + 1, 2)}${pad(date.getDate(), 2)}` }));
  }, []);

  useEffect(() => {
    if (invoicesList.length) {
      if (id) {
        const invoice = invoicesList.find(item => (item._id === id));
        const data = {
          id: invoice._id,
          address: invoice.address,
          zip: invoice.zip,
          company: invoice.clientCompany,
          destination: invoice.clientName,
          clientNumber: invoice.clientNumber,
          date: invoice.date,
          email: invoice.email,
          invoice: invoice.invoice,
          payed: invoice.payed,
          phone: invoice.phone,
          total: invoice.total,
        };

        setForm(data);
        setLines(invoice.lines);
      }
    }
  }, [invoicesList]);

  const calculateInvoiceNumber = () => {
    if (invoicesList.length && !id) {
      const hasInvoiceNumber = invoicesList.find(item => {
        if (item.invoice === form.invoice) {
          return true;
        }
      });

      if (hasInvoiceNumber) {
        const date = new Date(form.date);
        let countInvoice = count;
        countInvoice += 1;
        setCount(countInvoice);
        // console.log('countInvoice', countInvoice);
        setForm((prevState) => ({ ...prevState, invoice: `DE${date.getFullYear()}${pad(date.getMonth() + 1, 2)}${pad(date.getDate(), 2)}-${countInvoice}` }));
      }
    }
  }

  useEffect(() => {
    if (!isChangedInvoiceNumber && invoicesList.length === 0) {
      calculateInvoiceNumber();
    }
  }, [form.invoice, invoicesList]);


  const handleChangeDate = (date) => {
    let invoiceNumber;
    if (date && !isChangedInvoiceNumber) {
      invoiceNumber = `DE${date.getFullYear()}${pad(date.getMonth() + 1, 2)}${pad(date.getDate(), 2)}`;
    } else {
      invoiceNumber = '';
    }
    setForm((prevState) => ({ ...prevState, date: date }));
    setForm((prevState) => ({ ...prevState, invoice: invoiceNumber }));
    setCount(0);
  };

  const handleClickShowClients = () => {
    setState(true);
  }

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
    setError((prevState) => ({ ...prevState, destination: form.destination === '' }));
    setError((prevState) => ({ ...prevState, address: form.address === '' }));
    setDisabled(false);
    if (e.target.name === 'invoice') setIsChangedInvoiceNumber(true);
  };

  const handdleAddTranslation = (text, i) => {
    const newLines = [...lines];
    newLines[i].description = text;
    setLines(newLines);
  }

  const handleSelectCliente = (event, row) => {
    const { address, clientCompany, clientName, email, phone, zip, _id, clientNumber } = row;
    setForm((prevState) => ({ ...prevState, clientId: _id }));
    setForm((prevState) => ({ ...prevState, address }));
    setForm((prevState) => ({ ...prevState, zip }));
    setForm((prevState) => ({ ...prevState, clientNumber: clientNumber }));
    setForm((prevState) => ({ ...prevState, company: clientCompany }));
    setForm((prevState) => ({ ...prevState, destination: clientName }));
    setForm((prevState) => ({ ...prevState, email }));
    setForm((prevState) => ({ ...prevState, phone }));
    setState(false);
    setDisbledSaveClient(false);
  }

  const handdleSaveClient = () => {
    if (form.destination && form.address) {
      setError((prevState) => ({ ...prevState, destination: form.destination === '' }));
      setError((prevState) => ({ ...prevState, address: form.address === '' }));
      setDisbledSaveClient(true);
      axios.post(`${apiUrl}/collections/save/${localStorage.client}_clientes?token=${localStorage.token}`, {
        data: {
          _id: form.clientId,
          clientNumber: form.clientId ? form.clientNumber : clients.length + 1,
          clientName: form.destination,
          clientCompany: form.company,
          address: form.address,
          zip: form.zip,
          phone: form.phone,
          email: form.email,
        }
      })
        .then(function (response) {
          const isUpdate = clients.findIndex(item => item._id === response.data._id);
          const newArray = [...clients];
          newArray[isUpdate] = response.data
          if (isUpdate >= 0) {
            setClients(newArray);
            NotificationManager.success('Client mis à jour', 'Succès');
          } else {
            setClients([response.data, ...clients])
            NotificationManager.success('Client enregistré', 'Succès');
          };

        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setError((prevState) => ({ ...prevState, destination: form.destination === '' }));
      setError((prevState) => ({ ...prevState, address: form.address === '' }));
    }
  }

  const handdleAddLine = () => {
    setLines([...lines, {
      description: "",
      unit: "",
      quantity: "",
      unitPrice: "",
      total: ""
    }]);
  }

  const handleChangeLines = (e, i) => {
    const { name, value } = e.target;
    const newLines = [...lines];
    newLines[i][name] = value;

    if (newLines[i].quantity && newLines[i].unitPrice) {
      let totalValue;
      totalValue = +newLines[i].quantity * +newLines[i].unitPrice;
      totalValue = totalValue.toFixed(2);
      newLines[i].total = totalValue;
    }

    setLines(newLines);
  }

  const handdleDeleteRow = (i) => {
    const newLines = [...lines];
    newLines.splice(i, 1);
    setLines(newLines);
  }

  const calculateTotal = (value) => {
    setTotalInvoice(value);
  }

  const handleSave = () => {
    const data = {
      _id: form.id || null,
      date: form.date,
      invoice: form.invoice,
      clientNumber: form.clientNumber,
      clientName: form.destination,
      clientCompany: form.company,
      address: form.address,
      zip: form.zip,
      phone: form.phone,
      email: form.email,
      lines: lines,
      total: totalInvoice,
      payed: false
    };
    setDisabled(true);

    axios.post(`${apiUrl}/collections/save/${localStorage.client}_quote?token=${localStorage.token}`, { data })
      .then(function (response) {
        NotificationManager.success('Success saved', 'Invoice');
        history.push("/quote/list");
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  const classes = useStyles();

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div style={{ marginTop: '25px' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Container fixed>
          <Grid container spacing={3}>
            <Hidden xsDown>
              <Grid item md={6} style={{ textAlign: 'center', fontSize: '12px', color: '#888' }}>
                <img src={myData.imageLogo} style={{ width: '60%', marginTop: '40px', marginBottom: '20px' }} />
                <br />
                {myData.email}
                <br />
                {myData.phone}
                <br />
                {myData.address}
                <br />
                {myData.zip}
              </Grid>
            </Hidden>
            <Grid item md={6}>
              <Grid container spacing={3}>
                <Grid xs={6} className={classes.padding}>
                  <KeyboardDatePicker
                    style={{ marginTop: 0 }}
                    fullWidth
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="dd-MM-yyyy"
                    value={form.date}
                    onChange={handleChangeDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid xs={6} className={classes.padding}>
                  <TextField
                    fullWidth
                    name="invoice"
                    onChange={handleChangeForm}
                    id="invoice"
                    // disabled
                    label="Devis N."
                    value={form.invoice}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  error={error.destination}
                  name="destination"
                  onChange={handleChangeForm}
                  fullWidth
                  id="destination"
                  label="Destination"
                  value={form.destination}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clients"
                          onClick={handleClickShowClients}
                        >
                          <AccountBoxIcon />
                        </IconButton>
                        <IconButton
                          aria-label="save"
                          disabled={disbledSaveClient}
                          onClick={handdleSaveClient}
                        >
                          <SaveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  name="company"
                  onChange={handleChangeForm}
                  fullWidth
                  id="standard-company"
                  label="Nom société"
                  value={form.company}
                />
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  error={error.address}
                  name="address"
                  id="standard-textarea"
                  label="Address"
                  onChange={handleChangeForm}
                  fullWidth
                  multiline
                  value={form.address}
                />
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  error={error.zip}
                  name="zip"
                  id="standard-zip"
                  label="Code postal"
                  onChange={handleChangeForm}
                  fullWidth
                  multiline
                  value={form.zip}
                />
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  error={error.phone}
                  name="phone"
                  onChange={handleChangeForm}
                  fullWidth
                  id="phone"
                  label="Téléphone"
                  value={form.phone}
                />
              </Grid>
              <Grid container spacing={3} className={classes.padding}>
                <TextField
                  error={error.email}
                  name="email"
                  onChange={handleChangeForm}
                  fullWidth
                  id="email"
                  label="Email"
                  value={form.email}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '50px' }}>
            <AddItemContainer
              lines={lines}
              handdleAddLine={handdleAddLine}
              handleChangeLines={handleChangeLines}
              handdleDeleteRow={handdleDeleteRow}
              calculateTotal={calculateTotal}
              handdleAddTranslation={handdleAddTranslation}
            />
          </Grid>
        </Container>
        <Container fixed style={{ marginTop: '50px', fontSize: '13px' }}>
          <div container style={{ backgroundColor: '#888', height: '5px' }} />
          <Grid container>
            <Grid item md={8} xs={12}>
              <Grid container>
                <Grid item md={2} style={{ color: '#888', padding: '5px 10px' }}>
                  <strong>BANQUE:</strong>
                </Grid>
                <Grid item md={10} style={{ color: '#888', padding: '5px 10px' }}>
                  {myData.bank}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2} style={{ color: '#888', padding: '5px 10px' }}>
                  <strong>IBAN:</strong>
                </Grid>
                <Grid item md={10} style={{ color: '#888', padding: '5px 10px' }}>
                  {myData.iban}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2} style={{ color: '#888', padding: '5px 10px' }}>
                  <strong>CODE BIC:</strong>
                </Grid>
                <Grid item md={10} style={{ color: '#888', padding: '5px 10px' }}>
                  {myData.bic}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item md={12} style={{ color: '#888', padding: '25px 10px', textAlign: 'center' }}>
                <Button variant="contained" color="secondary" onClick={handleSave} disabled={disabled}>Enregistrer le devis</Button>
              </Grid>
            </Grid>
          </Grid>
          <div container style={{ backgroundColor: '#888', height: '5px' }} />
        </Container>
        <Container fixed style={{ marginTop: '30px', fontSize: '13px' }}>
          <Grid item xs={12} style={{ color: '#888', padding: '5px 10px', textAlign: 'center', backgroundColor: '#ccc' }}>
            <strong>SIRET:</strong> {myData.siret}
          </Grid>
        </Container>
        <br />
        <br />
        <Drawer anchor="top" open={state} onClose={toggleDrawer(false)}>
          <ClientsTable clients={clients} handleSelectCliente={handleSelectCliente} />
        </Drawer>
      </MuiPickersUtilsProvider >
    </div>
  );
};

AddQuote.propTypes = {

};

export default AddQuote;