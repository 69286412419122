import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import { NotificationManager } from 'react-notifications';
import AddProviderSiret from './AddProviderSiret';
import { apiUrl } from './vars';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    display: 'none',
  },
}));

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      decimalSeparator=","
      isNumericString
      prefix="€ "
    />
  );
}

const AddProvider = ({
  handleChangeForm,
  saveProvider,
  form,
  closeAddProviderModal,
  resetCloseAddProviderModal,
  changePhoto,
  handleChangeDate,
  changeSiret,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [editSiret, setEditSiret] = useState('');
  const [error, setError] = useState({
    siret: false,
    name: false,
    date: false,
    value: false,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetCloseAddProviderModal();
  };

  useEffect(() => {
    if (closeAddProviderModal === 'close') {
      handleClose();
      resetCloseAddProviderModal();
    } else if (closeAddProviderModal === 'open') {
      handleOpen();
    }
  }, [closeAddProviderModal]);

  const uploadImage = () => {
    const url = `/upload.php`;
    const fileInput = document.querySelector('input[type="file"]');
    const formData = new FormData();
    formData.append('photo', fileInput.files[0]);
    setLoadingImage(true);
    axios.post(url,
      formData,
    )
      .then(function (response) {
        changePhoto(response.data.file);
        setLoadingImage(false);
        alert(response.data.file ? 'O ficheiro foi carregado com sucesso' : response.data);
      })
      .catch(function (error) {
        // changePhoto('d54ef96d488bc37b4e93b9e5abd63cf6-IMG_20210124_124440.jpg');
        setLoadingImage(false);
        alert(error);
      });
  }

  const submit = () => {
    saveProvider();
  }

  const handleSelectImage = (e) => {
    uploadImage();
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const handleSelectSiret = (e, provider) => {
    changeSiret(provider);
    setState(false);
    setEditSiret(provider._id);
  }

  useEffect(() => {
    setError((prevState) => ({ ...prevState, siret: form.siret === '' }));
    setError((prevState) => ({ ...prevState, provider: form.provider === '' }));
  }, [form]);

  const handdleSaveSiret = () => {
    if (form.siret === '' || form.provider === '') {
      setError((prevState) => ({ ...prevState, siret: form.siret === '' }));
      setError((prevState) => ({ ...prevState, provider: form.provider === '' }));
    } else {
      const data = {
        siret: form.siret,
        name: form.provider,
        _id: editSiret || null,
      };

      axios.post(`${apiUrl}/collections/save/${localStorage.client}_providers_siret?token=${localStorage.token}`, { data })
        .then(function (response) {

          NotificationManager.success('Ajouté avec succès', 'Succès');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }



  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <div style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 999, maxWidth: '400' }}>
          <Fab color="primary" aria-label="add" onClick={handleOpen}>
            <AddIcon />
          </Fab>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          disableBackdropClick
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open} style={{
            overflow: 'auto',
            maxHeight: '100%',
          }}>
            <div className={classes.paper}>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={error.siret}
                        id="siret"
                        name="siret"
                        value={form.siret || ''}
                        label="Siret"
                        fullWidth
                        onChange={handleChangeForm}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clientss"
                                onClick={() => setState(true)}
                              >
                                <AccountBoxIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        id="provider"
                        error={error.provider}
                        name="provider"
                        value={form.provider || ''}
                        label="Nome do Fornecedor"
                        fullWidth
                        onChange={handleChangeForm}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clientss"
                                onClick={() => setState(true)}
                              >
                                <AccountBoxIcon />
                              </IconButton>
                              <IconButton
                                aria-label="save"
                                // disabled={disbledSaveClient}
                                onClick={handdleSaveSiret}
                              >
                                <SaveIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="invoiceNumber"
                        name="invoiceNumber"
                        value={form.invoiceNumber || ''}
                        label="N. Fatura"
                        fullWidth
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        style={{ marginTop: 0 }}
                        fullWidth
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date"
                        format="dd-MM-yyyy"
                        value={form.date || ''}
                        onChange={handleChangeDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="paymentMethod-label">Mode de paiement</InputLabel>
                        <Select
                          labelId="paymentMethod-label"
                          fullWidth
                          id="paymentMethod"
                          name="paymentMethod"
                          value={form.paymentMethod || ''}
                          onChange={handleChangeForm}
                        >
                          <MenuItem value="Payé en liquide">Payé en liquide</MenuItem>
                          <MenuItem value="Payé par carte">Payé par carte</MenuItem>
                          <MenuItem value="Chèque bancaire">Chèque bancaire</MenuItem>
                          <MenuItem value="Virement">Virement</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Montant"
                        name="value"
                        value={form.value || ''}
                        onChange={handleChangeForm}
                        fullWidth
                        id="value"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8} align="right">
                      <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={handleSelectImage} />
                      <label htmlFor="icon-button-file">
                        <Button
                          component="span"
                          aria-label="upload picture"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.button}
                          startIcon={<PhotoCamera />}
                        >
                          Upload photo
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div style={{
                    position: 'relative',
                  }}>
                    <img src={`/upload/${form.image || 'no-image.png'}`} width="100%" />
                    {loadingImage && (
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                      }}>
                        <CircularProgress color="inherit" />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="default" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button variant="contained" color="secondary" onClick={submit}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
        <Drawer anchor="top" open={state} onClose={toggleDrawer(false)}>
          <AddProviderSiret handleSelectSiret={handleSelectSiret} />
        </Drawer>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default AddProvider;
