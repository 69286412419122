import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import numero from '../numeros';

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const borderColor = color;
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
    fontSize: 12,
    fontStyle: 'bold',
    color: '#444',
    marginTop: 10,
  },
  number: {
    width: '60%',
    textAlign: 'left',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    lineHeight: 0,
  },
  description: {
    width: '25%',
    textAlign: 'center',
    backgroundColor: borderColor,
    color: '#444',
    padding: 8,
    lineHeight: 0,
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
    lineHeight: 0,
  },
  numbertext: {
    width: '100%',
    textAlign: 'left',
    lineHeight: 0,
    fontSize: 10,
  },
  numberValueText: {
    width: '100%',
    fontStyle: 'oblique',
    textAlign: 'left',
    lineHeight: 0,
    fontSize: 10,
  },
});


const InvoiceTableFooter = ({ items, invoice, intl }) => (
  <View style={styles.row} fixed>
    <View style={styles.number}>
      <Text style={styles.numbertext}>Arrêtée la présente facture à la somme de:</Text>
      <Text style={styles.numberValueText}>{numero.porExtenso(invoice.total, numero.estilo.monetario)}</Text>
    </View>
    <Text style={styles.description}>TOTAL</Text>
    <Text style={styles.total}>{intl.formatNumber(invoice.total, { style: 'currency', currency: 'EUR' })}</Text>
  </View>
);

export default InvoiceTableFooter