import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import TranslatePage from './TranslatePage';
import numero from './numeros';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      decimalSeparator=","
      isNumericString
      prefix="€ "
    />
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const AddItemContainer = ({
  lines,
  handdleAddLine,
  handleChangeLines,
  handdleDeleteRow,
  calculateTotal,
  handdleAddTranslation,
}) => {
  const classes = useStyles();
  let total = 0;
  lines.map(item => {
    if (item.total) {
      total = +(total) + +(item.total);
      total = total.toFixed(2);
      calculateTotal(total);
    }
  });

  const changeTranslateText = (text, i) => {
    handdleAddTranslation(text, i);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell style={{ width: '50%', whiteSpace: 'nowrap' }}>Description</StyledTableCell>
            <StyledTableCell style={{ width: '10%', whiteSpace: 'nowrap' }}>Unité</StyledTableCell>
            <StyledTableCell align="right" style={{ width: '10%', whiteSpace: 'nowrap' }}>Quantité</StyledTableCell>
            <StyledTableCell align="right" style={{ width: '15%', whiteSpace: 'nowrap' }}>Coût unitaire</StyledTableCell>
            <StyledTableCell align="right" style={{ width: '15%', whiteSpace: 'nowrap' }}>Montant</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>
                <Tooltip title="Delete line" placement="right">
                  <Button variant="contained" color="secondary" onClick={() => handdleDeleteRow(i)}>-</Button>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  fullWidth
                  name="description"
                  multiline
                  onChange={(e) => handleChangeLines(e, i)}
                  id="description"
                  value={row.description}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TranslatePage changeTranslateText={changeTranslateText} i={i} />
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <TextField
                  name="unit"
                  onChange={(e) => handleChangeLines(e, i)}
                  id="unit"
                  value={row.unit}
                />
              </StyledTableCell>
              <StyledTableCell align="right"><TextField
                name="quantity"
                onChange={(e) => handleChangeLines(e, i)}
                fullWidth
                id="quantity"
                type="number"
                value={row.quantity}
              />
              </StyledTableCell>
              <StyledTableCell align="right">
                <TextField
                  name="unitPrice"
                  onChange={(e) => handleChangeLines(e, i)}
                  fullWidth
                  id="unitPrice"
                  value={row.unitPrice}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <TextField
                  name="total"
                  style={{ textAlign: 'right' }}
                  onChange={(e) => handleChangeLines(e, i)}
                  fullWidth
                  id="total"
                  value={row.total}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell>
              <Tooltip title="Add new line" placement="right">
                <Button variant="contained" color="primary" onClick={handdleAddLine}>+</Button>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell colSpan={3}>
              {(total !== 0) && (
                <>
                  <i>Arrêtée la présente facture à la somme de:</i>
                  <br />
                  <b>{numero.porExtenso(total, numero.estilo.monetario)}</b>
                </>
              )}
            </StyledTableCell>
            <StyledTableCell align="right" style={{ fontSize: '18px', paddingRight: '22px' }}>
              Total:
            </StyledTableCell>
            <StyledTableCell align="right" style={{ fontSize: '18px', paddingRight: '22px' }}>
              <TextField
                name="total"
                style={{ textAlign: 'right' }}
                fullWidth
                disabled
                id="total"
                value={total}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AddItemContainer;
