import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FormattedNumber } from 'react-intl';

export const sumObjects = (obj1, obj2) => {
  const result = {};

  for (const year in obj1) {
    if (obj1.hasOwnProperty(year) && obj2.hasOwnProperty(year)) {
      result[year] = {};
      for (const month in obj1[year]) {
        if (obj1[year].hasOwnProperty(month) && obj2[year].hasOwnProperty(month)) {
          result[year][month] = obj1[year][month] + obj2[year][month];
        } else if (obj1[year].hasOwnProperty(month)) {
          result[year][month] = obj1[year][month];
        } else if (obj2[year].hasOwnProperty(month)) {
          result[year][month] = obj2[year][month];
        }
      }
    }
  }

  return result;
}


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        callback: value => new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,

    },
  },
};

const labels = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const colors = ['#cadb5e', '#84cc70', '#52c4bd', '#4f5db8', '#d46ad4', '#d6787c']

const MonthlyChart = ({ values, valuesP }) => {
  const sumResult = sumObjects(values, valuesP);

  const data = {
    labels,
    datasets: Object.keys(sumResult).flatMap((value, i) => {
      const data = sumResult[value];
      for (let month = 0; month < 12; month++) {
        if (!(month in data)) {
          data[month] = 0;
        }
      }
      return {
        type: 'bar',
        label: value,
        data: Object.values(data),
        backgroundColor: colors[i],
      }
    }),
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Bar options={options} data={data} height={75} />
    </div>
  );
}

export default MonthlyChart