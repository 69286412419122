import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: color,
  },
});

const InvoiceItemsTable = ({ invoice, translations, intl }) => {
  const tableRowsCount = invoice.lines.length > 10 ? invoice.lines.length : 10;



  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader translations={translations} />
      <InvoiceTableRow items={invoice.lines} intl={intl} />
      {/* <InvoiceTableBlankSpace rowsCount={tableRowsCount - invoice.lines.length} /> */}
    </View>
  )
};

export default InvoiceItemsTable