import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useHistory } from "react-router-dom";
import LeftMenu from './LeftMenu';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, ListItem, TextField } from '@material-ui/core';
import axios from 'axios';
import { apiUrl } from './vars';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(!!localStorage.token);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [state, setState] = React.useState(false);
  const [alertCount, setAlertCount] = React.useState(0);
  const today = new Date();

  const [form, setForm] = useState({
    password: '',
    newPassword: '',
    repeatPassword: '',
  });

  const [error, setError] = useState({
    password: false,
    newPassword: false,
    repeatPassword: false
  });

  const [openModal, setOpenModal] = React.useState(false);

  const history = useHistory();
  useEffect(() => {



    // fetch(`${apiUrl}/collections/get/${localStorage.client}_alerts?token=${localStorage.token}`, {
    //   method: 'post',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     filter: {
    //       date: {
    //         $lte: today.getTime()
    //       },
    //       readed: 0
    //     },
    //   })
    // })
    axios.post(`${apiUrl}/collections/get/${localStorage.client}_alerts?token=${localStorage.token}`,
      {
        filter: {
          date: {
            $lte: today.getTime()
          },
          readed: 0
        },
      })
      .then(function (response) {
        setAlertCount(response.data.total)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [history.location.pathname])

  if (!localStorage.token) {
    localStorage.clear();
    history.push('/login');
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteInvoice = () => {
    history.push('/invoice/delete');
  }

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    window.location.href = '/';
  };

  const toggleDrawer = (open, url) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (url) history.push(url);
    setState(open);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setError((prevState) => ({ ...prevState, password: false }));
    setError((prevState) => ({ ...prevState, repeatPassword: false }));
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangePassword = () => {
    const { password, newPassword, repeatPassword } = form;
    if (password && newPassword && repeatPassword) {
      if (newPassword === repeatPassword) {
        const data = JSON.parse(localStorage.obj)
        const user = { ...data, password: newPassword }
        axios.post(`${apiUrl}/cockpit/authUser?token=d1e5f80c6717c910217a4c4e1019ac`,
          {
            user: user.name,
            password: form.password,
          }
        ).then(function (response) {
          setError((prevState) => ({ ...prevState, password: false }));
          setError((prevState) => ({ ...prevState, repeatPassword: false }));

          if (response.status === 200) {
            axios.post(`${apiUrl}/cockpit/saveUser?token=${localStorage.token}`, { user })
              .then(() => {
                handleLogout()
              })
              .then(user => console.log(user));
          }
        })
          .catch(function (error) {
            setError((prevState) => ({ ...prevState, password: 'true' }));
            console.log(error);
          });
      } else {
        setError((prevState) => ({ ...prevState, repeatPassword: 'true' }));
      }
    }

  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {JSON.parse(localStorage.obj || '{}').name}
          </Typography>
          {auth && (
            <div style={{ display: 'flex' }}>
              <ListItem button onClick={toggleDrawer(false, '/dashboard')}>
                <TimelineIcon />
              </ListItem>
              <ListItem button onClick={toggleDrawer(false, '/alerts')}>
                <Badge badgeContent={alertCount} color="error">
                  <NotificationsActiveIcon />
                </Badge>
              </ListItem>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => history.push('/alerts')}>Messages d'alerte</MenuItem>
                <Divider light />
                <MenuItem onClick={() => history.push('/dashboard')}>Statistiques</MenuItem>
                <Divider light />
                <MenuItem onClick={deleteInvoice}>Supprimer factures</MenuItem>
                <Divider light />
                <MenuItem onClick={() => setOpenModal(true)}>Changer le mot de passe</MenuItem>
                <Divider light />
                <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <LeftMenu toggleDrawer={toggleDrawer} state={state} />

      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Changer le mot de passe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer votre mot de passe actuel
          </DialogContentText>

          <TextField
            autoFocus
            error={error.password}
            margin="dense"
            id="password"
            name="password"
            label="Mot de passe actuel"
            type="password"
            fullWidth
            onChange={handleChangeForm}
          />
          {error.password && (
            <FormHelperText id="component-error-text" style={{ color: 'red' }}>Le mot de passe actuel est incorrect!</FormHelperText>
          )}
          <br />
          <br />
          <TextField
            margin="dense"
            error={error.repeatPassword}
            id="newPassword"
            name="newPassword"
            label="Nouveau mot de passe"
            type="password"
            fullWidth
            onChange={handleChangeForm}
          />

          <TextField
            margin="dense"
            id="repeatPassword"
            error={error.repeatPassword}
            name="repeatPassword"
            label="Répété le nouveau mot de passe"
            type="password"
            fullWidth
            onChange={handleChangeForm}
          />
          {error.repeatPassword && (
            <FormHelperText id="component-error-text" style={{ color: 'red' }}>La répétition du mot de passe est différente</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Annuler
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Header;