import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTopWidth: 5,
    borderTopColor: color,
    flexWrap: 'wrap',
    borderBottomWidth: 5,
    borderBottomColor: color,
    marginTop: 25,
  },
  col: {
    flexWrap: 'wrap',
    width: '50%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colLabel: {
    fontSize: 10,
    flexDirection: 'row',
    alignItems: 'top',
    fontStyle: 'bold',
    flexGrow: 1,
    color: "#888",
  },
  colLabelTextL: {
    alignItems: 'top',
    width: '35%',
    textAlign: 'right',
    color: "#222",
    marginRight: 5,
    lineHeight: 0,
  },
  colLabelTextR: {
    flexWrap: 'wrap',
    alignItems: 'top',
    width: '65%',
    textAlign: 'left',
    lineHeight: 0,
  },
  logo: {
    width: 180,
    height: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 5,
  },
  companyInfo: {
    color: '#888',
    width: '100%',
    textAlign: 'center',
    lineHeight: 0,
    fontSize: 10,
  },
  colLabelValues: {
    color: '#888',
    width: '100%',
    textAlign: 'center',
    lineHeight: 0,
    fontSize: 10,
    borderWidth: 1,
    borderColor: color,
  }
});


const InvoiceHeader = ({ invoice, intl, translations }) => {
  const { company, imageLogo, email, phone, address, zip } = JSON.parse(localStorage.cltObj || '{}');

  return (
    <View style={styles.row} fixed>
      <View style={styles.col}>
        <Text style={styles.companyInfo}>{company}</Text>
        <Image style={styles.logo} src={imageLogo} />
        <Text style={styles.companyInfo}>{email}</Text>
        <Text style={styles.companyInfo}>{phone}</Text>
        <Text style={styles.companyInfo}>{address}</Text>
        <Text style={styles.companyInfo}>{zip}</Text>
      </View>
      <View style={styles.col}>
        <View style={styles.colLabel}>
          <Text style={styles.colLabelTextL}>Date</Text>
          <Text style={styles.colLabelTextR}>{intl.formatDate(invoice.date)}</Text>
        </View>
        {(invoice.invoice !== '') && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}>{translations.type}</Text>
            <Text style={styles.colLabelTextR}>{invoice.invoice}</Text>
          </View>
        )}
        <View style={styles.colLabel}>
          <Text style={styles.colLabelTextL}>Destinataire</Text>
          <Text style={styles.colLabelTextR}>{invoice.clientName || ''}</Text>
        </View>
        {(invoice.clientCompany !== "") && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}></Text>
            <Text style={styles.colLabelTextR}>{invoice.clientCompany || ''}</Text>
          </View>
        )}
        {(invoice.address !== '') && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}>Addresse</Text>
            <Text style={styles.colLabelTextR}>{invoice.address || ''}</Text>
          </View>
        )}
        {(invoice.zip !== "") && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}></Text>
            <Text style={styles.colLabelTextR}>{invoice.zip || ''}</Text>
          </View>
        )}
        {(invoice.phone !== '') && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}>Téléphone</Text>
            <Text style={styles.colLabelTextR}>{invoice.phone || ''}</Text>
          </View>
        )}
        {(invoice.email !== '') && (
          <View style={styles.colLabel}>
            <Text style={styles.colLabelTextL}>Email</Text>
            <Text style={styles.colLabelTextR}>{invoice.email || ''}</Text>
          </View>
        )}
      </View>
    </View>
  )
};

export default InvoiceHeader;
