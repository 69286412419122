import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { FormattedNumber, injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmailIcon from '@material-ui/icons/Email';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { apiUrl } from './vars'

const limit = 25;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const classes = useRowStyles();

  const handleSend = (status) => {
    const date = new Date()

    const data = { ...row }
    data.send = status;
    data.sendDate = date.getTime();

    axios.post(`http://joelcalheiros.com/admin/api/collections/save/${localStorage.client}?token=${localStorage.token}`, { data })
      .then(function (response) {
        props.getInvoices();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handlePay = (status) => {
    const data = { ...row }
    data.payed = status;

    axios.post(`${apiUrl}/collections/save/${localStorage.client}?token=${localStorage.token}`, { data })
      .then(function (response) {
        props.setPayed(data._id, status)


        axios.post(`${apiUrl}/collections/get/${localStorage.client}_alerts?token=${localStorage.token}`,
          {
            filter: {
              invoiceId: data._id
            },
          })
          .then(function (response) {
            const { entries } = response.data;
            if (entries.length > 0) {

              const data = {
                ...entries[0],
                readed: 1
              };

              axios.post(`${apiUrl}/collections/save/${localStorage.client}_alerts?token=${localStorage.token}`, { data })
                .then(function () {

                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

    setOpenConfirm(false)
  }

  let history = useHistory();
  const editRow = (id) => {
    history.push(`/invoices/edit/${id}`);
  }

  const viewInvoice = (id) => {
    // window.open(`/invoices/detail/${id}`,)
    history.push(`/invoices/detail/${id}`);
  };

  if (!row.deleted) {
    return (
      <>
        <TableRow className={classes.root} style={{ background: row.payed ? '#ccf9cc' : '' }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" style={{ whiteSpace: 'nowrap' }}>
            {row.invoice}
          </TableCell>
          <TableCell align="left">{row.clientName}</TableCell>
          <TableCell align="left">{row.clientCompany}</TableCell>
          <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>{row.email}</TableCell>
          <TableCell align="left">
            <Moment format="DD-MM-YYYY" style={{ whiteSpace: 'nowrap' }}>
              {row.date}
            </Moment>
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            <FormattedNumber value={row.total} style="currency" currency="EUR" />
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            {row.payed ? <Tooltip title="Marquer comme non payé" placement="top">
              <IconButton
                variant="contained"
                onClick={() => {
                  setOpenConfirm(true)
                  // handlePay(false)
                }}
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip> : <Tooltip title="Marquer comme payé" placement="top">
              <IconButton variant="contained" color="secondary" onClick={() => {
                handlePay(true)
              }}>
                <RadioButtonUncheckedIcon />
              </IconButton>
            </Tooltip>}
            {row.send ? <Tooltip title="Envoyé" placement="top">
              <IconButton variant="contained" onClick={() => {
                handleSend(false)
              }}>
                <EmailIcon />
              </IconButton>
            </Tooltip> : <Tooltip title="Non Envoyé" placement="top">
              <IconButton variant="contained" color="secondary" onClick={() => {
                handleSend(true);
              }}>
                <MailOutlineIcon />
              </IconButton>
            </Tooltip>}
            <Tooltip title="Modifier la facture" placement="top">
              <IconButton variant="contained" onClick={() => editRow(row._id)}>
                <EditIcon />
              </IconButton >
            </Tooltip>
            <Tooltip title="Afficher la facture PDF" placement="top">
              <IconButton variant="contained" onClick={() => viewInvoice(row._id)}><PictureAsPdfIcon /></IconButton >
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: row.payed ? '#e1ffe1' : '#f5f5f5' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="p" gutterBottom component="div">
                  {`Facture - ${row.invoice}`}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell width="50%">Description</TableCell>
                      <TableCell>Unité</TableCell>
                      <TableCell align="right">Quantité </TableCell>
                      <TableCell align="right">Coût unitaire</TableCell>
                      <TableCell align="right">Montant (€)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.lines.map((historyRow, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {historyRow.description}
                        </TableCell>
                        <TableCell>{historyRow.unit}</TableCell>
                        <TableCell align="right">{historyRow.quantity}</TableCell>
                        <TableCell align="right">
                          {historyRow.unitPrice && (
                            <FormattedNumber value={historyRow.unitPrice} style="currency" currency="EUR" />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {historyRow.total && (
                            <FormattedNumber value={historyRow.total} style="currency" currency="EUR" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={4}>
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell align="right" colSpan={1}>
                        <strong><FormattedNumber value={row.total} style="currency" currency="EUR" /></strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>

        <Dialog
          open={openConfirm}
          onClose={() => { setOpenConfirm(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Marquer comme non payé
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Vous êtes sûr que vous voulez marquer comme non payé?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirm(false)}>Annuler</Button>
            <Button onClick={() => handlePay(false)} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>



      </>
    );
  }

  return (<div />);

}

const InvoiceList = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(0);

  const handleChangePrev = () => {
    setPage(page - 1 * limit);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChangeNext = () => {
    setPage(page + 1 * limit);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [searchForm, setSearchForm] = useState({
    invoice: '',
    clientName: '',
    email: '',
    payed: 'non-payed',
  });

  useEffect(() => {
    axios.post(`${apiUrl}/collections/get/clientes?token=${localStorage.token}`, { filter: { client: localStorage.client } })
      .then((response) => {
        const data = response.data.entries[0];
        localStorage.setItem('cltObj', JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });

    getInvoices();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      getInvoices();
    }
  }, [page])

  const updateInvoices = response => {
    const allInvoices = response.data.entries;
    setTotal(response.data.total)
    setIsLoading(false)

    setInvoices(allInvoices);
  }

  const getInvoices = () => {
    const params = {
      limit,
      skip: page,
      filter: {},
      sort: {
        _id: -1
      }
    };

    if (searchForm.payed === 'non-payed') {
      params.filter = {
        ...params.filter,
        payed: ''
      }
    }

    if (searchForm.payed === 'payed') {
      params.filter = {
        ...params.filter,
        payed: 1
      }
    }

    if (searchForm.payed === 'all') {
      params.filter = {}
    }

    if (searchForm.invoice) {
      params.filter = {
        ...params.filter,
        invoice: { "$regex": searchForm.invoice }
      }
    }

    if (searchForm.clientName) {
      params.filter = {
        ...params.filter,
        clientName: { "$regex": searchForm.clientName }
      }
    }

    if (searchForm.email) {
      params.filter = {
        ...params.filter,
        email: { "$regex": searchForm.email }
      }
    }

    axios.post(`${apiUrl}/collections/get/${localStorage.client}?token=${localStorage.token}`,
      params)
      .then((response) => {
        if (searchForm.invoice || searchForm.clientName || searchForm.email || searchForm.payed) {
          updateInvoices(response)
        } else {
          const allInvoices = response.data.entries;
          setTotal(response.data.total)
          setIsLoading(false)

          setInvoices([...invoices, ...allInvoices]);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeTerm = (e) => {
    const { name, value } = e.target;

    setSearchForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearchTerm = () => {
    setPage(0)
    setInvoices([])
    getInvoices()
  };

  const setPayed = (id, status) => {
    const newInvoices = [...invoices];
    const selectedInvoice = newInvoices.findIndex(item => (item._id === id));

    newInvoices[selectedInvoice].payed = status;

    setInvoices(newInvoices);
  }

  return (
    <div style={{ marginTop: '25px', paddingBottom: '95px' }}>
      <Container fixed>
        <Paper style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Typography variant="h5">
            Recherche de facture
          </Typography>
          <Grid container spacing={3} style={{ marginBottom: '30px' }}>
            <Grid item xs={12} md={2} sm={3}>
              <TextField
                id="invoice"
                fullWidth
                label="Facture n.º"
                onChange={handleChangeTerm}
                name="invoice"
              />
            </Grid>
            <Grid item xs={12} md={5} sm={4}>
              <TextField
                id="clientName"
                fullWidth
                onChange={handleChangeTerm}
                label="Nom du client"
                name="clientName"
              />
            </Grid>
            <Grid item xs={12} md={2} sm={2}>
              <TextField
                id="email"
                fullWidth
                onChange={handleChangeTerm}
                label="Email"
                name="email"
              />
            </Grid>
            <Grid item xs={12} md={3} sm={3} style={{ position: 'relative' }}>
              <FormControl className={classes.formControl}>
                <InputLabel id="payed-select-label">Etat</InputLabel>
                <Select
                  labelId="payed-select-label"
                  id="payed"
                  name="payed"
                  value={searchForm.payed}
                  onChange={handleChangeTerm}
                >
                  <MenuItem value='non-payed'>Non payé</MenuItem>
                  <MenuItem value="all">Toute</MenuItem>
                  <MenuItem value='payed'>Payé</MenuItem>
                </Select>
              </FormControl>

              <div style={{
                position: 'absolute', top: '20px',
                right: 0
              }}>
                <IconButton
                  aria-label="toggle"
                  disabled={!searchForm.invoice && !searchForm.clientName && !searchForm.email && !searchForm.payed}
                  onClick={
                    handleSearchTerm
                  }
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  aria-label="toggle"
                  onClick={() =>
                    location.reload()
                  }
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </Grid>

          </Grid>
        </Paper>
      </Container>
      <div style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 999 }}>
        <Link to="/invoices/add">
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Link>
      </div>
      {isLoading ? (
        <div style={{
          height: 500, display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress color="inherit" />
        </div>) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell width="120px">Facture N.</TableCell>
                  <TableCell>Nom du client</TableCell>
                  <TableCell>Nom de l'entreprise</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell width="120px">Date</TableCell>
                  <TableCell align="right">Montant</TableCell>
                  <TableCell width="180px" />
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((row) => (
                  <Row key={row._id} row={row} getInvoices={getInvoices} setPayed={setPayed} intl={props.intl} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: 25, display: 'flex', justifyContent: 'space-between', padding: '0 25px' }}>
            <div>
              {page > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleChangePrev}
                  display={page === 0}
                >
                  Page précédente
                </Button>
              )}
            </div>
            <div style={{ paddingLeft: 15 }}>Page {(page / limit) + 1} sur {(total / limit).toFixed(0)}</div>
            <div>
              {total > invoices.length && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleChangeNext}
                >
                  Page suivante
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default injectIntl(InvoiceList);
