import React, { Fragment } from 'react';
import { Page, Document, Text, StyleSheet } from '@react-pdf/renderer';
import InvoiceHeader from './InvoiceHeader'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 120,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  reportNote: {
    fontSize: 10,
    color: '#000',
    width: '100%',
    textAlign: 'center',
    marginTop: 10
  },
  paginator: {
    position: 'absolute',
    bottom: 40,
    right: 40,
    left: 40,
    color: '#888'
  }
});

const renderPdf = (invoice, translations, intl) => {
  return invoice.map((row, i) => {
    return (
      <Fragment key={i}>
        <Page size="A4" style={styles.page} wrap={true}>
          <InvoiceHeader invoice={row} translations={translations} intl={intl} fixed />
          <Text style={styles.reportNote}>{JSON.parse(localStorage.cltObj || '{}').note2}</Text>
          <InvoiceItemsTable invoice={row} translations={translations} intl={intl} />
          <InvoiceTableFooter items={row.lines} invoice={row} intl={intl} fixed />
          <InvoiceThankYouMsg translations={translations} fixed row={row} />
          <Text style={styles.paginator} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Fragment>
    );
  });
}

const Invoice = ({ invoice, translations, intl }) => {
  return (
    <Document title={invoice.length === 1 ? invoice[0].invoice : 'Fature'}>
      {renderPdf(invoice, translations, intl)}
    </Document>
  );
};

export default Invoice