import React, { Component, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import axios from 'axios';
import { NotificationContainer } from 'react-notifications';
import Login from './Login';
import Home from './Home';
import Detail from './Detail';
import Invoice from './Invoice';
import PdfDownload from './PdfDownload';
import NotFound from './NotFound';
import Header from './Header';
import AddClients from './AddClients';
import ClientsTable from './ClientsTable';
import 'react-notifications/lib/notifications.css';
import Providers from './Providers';
import Quote from './Quote';
import AddQuote from './AddQuote';
import QuoteDetail from './QuoteDetail';
import DeleteInvoice from './DeleteInvoice';
import BulkInvoice from './BulkInvoice';
import BulkProvider from './BulkProvider';
import ProviderDetailBulk from './ProviderDetailBulk';
// import InvoiceDetail from './InvoiceDetail';
import InvoiceList from './InvoiceList';
import Alerts from './Dashboard/Alerts';

const isLogged = localStorage.token;

const App = (props) => {
  if (isLogged) {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common.Authorization = `Bearer ${isLogged}`;
  }

  return (
    <div className="App">
      <Router>
        {isLogged ? <Header /> : <div />}
        <Switch>
          <Route exact path="/">
            {isLogged ? <InvoiceList /> : <Login />}
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/dashboard">
            <Home />
          </Route>
          <Route exact path="/invoices">
            <InvoiceList />
          </Route>
          <Route exact path="/invoices/detail/:id">
            <Detail />
          </Route>
          {/* <Route exact path="/invoice/detail/:client/:id">
            <InvoiceDetail />
          </Route> */}
          <Route path="/invoices/detail/pdf/:id">
            <PdfDownload />
          </Route>
          <Route path="/invoices/edit/:id">
            <Invoice />
          </Route>
          <Route path="/invoices/add">
            <Invoice />
          </Route>
          <Route path="/add-clients">
            <AddClients />
          </Route>
          <Route path="/clients/list">
            <ClientsTable />
          </Route>
          <Route path="/providers/list">
            <Providers />
          </Route>
          <Route path="/quote/list">
            <Quote />
          </Route>
          <Route path="/quote/add">
            <AddQuote />
          </Route>
          <Route path="/quote/edit/:id">
            <AddQuote />
          </Route>
          <Route exact path="/quote/detail/:id">
            <QuoteDetail />
          </Route>
          <Route exact path="/invoice/delete">
            <DeleteInvoice />
          </Route>
          <Route exact path="/invoice/bulk">
            <BulkInvoice />
          </Route>
          <Route exact path="/providers/bulk">
            <BulkProvider />
          </Route>
          <Route exact path="/providers/detail/bulk">
            <ProviderDetailBulk />
          </Route>
          <Route path="/invoices/*">
            <NotFound />
          </Route>
          <Route exact path="/alerts">
            <Alerts />
          </Route>
        </Switch>
      </Router>
      <NotificationContainer />
    </div>
  );
};

export default App;
