module.exports = [
    '',
    'mille',
    'million',
    'bilhões',
    'trilhões',
    'quatrilhões',
    'quintilhões',
    'sextilhões',
    'septilhões',
    'octilhões',
    'nonilhões',
    'decilhões',
    'undecilhões',
    'duodecilhões',
    'tredecilhões'
];
