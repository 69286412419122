import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const borderColor = color
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: color,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    color: 'white'
  },
  description: {
    width: '50%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    height: '100%',
  },
  unit: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 8,
    height: '100%',
  },
  unitPrice: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    height: '100%',
  },
  quantity: {
    width: '15%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    height: '100%',
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
    height: '100%',
  },

});

const InvoiceTableBlankSpace = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0)
  const rows = blankRows.map((x, i) =>
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.description}>-</Text>
      <Text style={styles.unit}>-</Text>
      <Text style={styles.unitPrice}>-</Text>
      <Text style={styles.quantity}>-</Text>
      <Text style={styles.amount}>-</Text>
    </View>
  )
  return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableBlankSpace

