import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';

const color = JSON.parse(localStorage.getItem('cltObj'))?.color || '#90e5fc';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexWrap: 'wrap',
    borderBottomWidth: 5,
    borderBottomColor: color,
    marginTop: 25,
  },
  colL: {
    flexWrap: 'wrap',
    width: '33%',
    paddingTop: 5,
    paddingBottom: 5,
  },
  colR: {
    flexWrap: 'wrap',
    width: '66%',
    paddingTop: 5,
    paddingBottom: 5,
  },
  colLabelGray: {
    fontSize: 10,
    flexDirection: 'row',
    alignItems: 'top',
    fontStyle: 'bold',
    flexGrow: 1,
    color: "#555",
  },
  colLabel: {
    fontSize: 10,
    flexDirection: 'row',
    alignItems: 'top',
    fontStyle: 'bold',
    flexGrow: 1,
    color: "#888",
  },

});

const ProviderHeader = ({ row, intl, translations }) => (
  <View style={styles.row}>
    <View style={styles.colL}>
      <Text style={styles.colLabelGray}>Siret</Text>
      <Text style={styles.companyInfo}>{row.siret ? row.siret : '- -'}</Text>
    </View>
    <View style={styles.colR}>
      <Text style={styles.colLabelGray}>Nom du fournisseur</Text>
      <Text style={styles.companyInfo}>{row.provider ? row.provider : '- -'}</Text>
    </View>
    <View style={styles.colL}>
      <Text style={styles.colLabelGray}>N. Facture</Text>
      <Text style={styles.companyInfo}>{row.invoiceNumber ? row.invoiceNumber : '- -'}</Text>
    </View>
    <View style={styles.colL}>
      <Text style={styles.colLabelGray}>Date</Text>
      <Text style={styles.companyInfo}>{row.date ? intl.formatDate(row.date) : '- -'}</Text>
    </View>
    <View style={styles.colL}>
      <Text style={styles.colLabelGray}>Mode de paiement</Text>
      <Text style={styles.companyInfo}>{row.paymentMethod ? row.paymentMethod : '- -'}</Text>
    </View>
    <View style={styles.colL}>
      <Text style={styles.colLabelGray}>Montant</Text>
      <Text style={styles.companyInfo}>{row.value ? intl.formatNumber(row.value, { style: 'currency', currency: 'EUR' }) : '- -'}</Text>
    </View>
  </View>
);

export default ProviderHeader;
