import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FormattedNumber } from 'react-intl';
import { sumObjects } from './MonthlyChart';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        callback: value => new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,

    },
  },
};

const labels = [
  '1er Trimestre',
  '2e Trimestre',
  '3e Trimestre',
  '4e Trimestre',
];

const colors = ['#ddf065', '#9ff587', '#65f0e7', '#6578f0', '#fa7ffa', '#f78b90']

const QuarterlyChart = ({ values, valuesP }) => {
  const sumResult = sumObjects(values, valuesP);

  const dataValues = labels.map((label, i) => {
    return Object.keys(sumResult).map(year => {
      let quartertotal = 0;
      if (i === 0) {
        quartertotal = (sumResult[year]?.[0] || 0) + (sumResult[year]?.[1] || 0) + (sumResult[year]?.[2] || 0)
      } else if (i === 1) {
        quartertotal = (sumResult[year]?.[4] || 0) + (sumResult[year]?.[5] || 0) + (sumResult[year]?.[6] || 0)
      } else if (i === 2) {
        quartertotal = (sumResult[year]?.[7] || 0) + (sumResult[year]?.[8] || 0) + (sumResult[year]?.[9] || 0)
      } else if (i === 3) {
        quartertotal = (sumResult[year]?.[10] || 0) + (sumResult[year]?.[11] || 0) + (sumResult[year]?.[12] || 0)
      }

      return quartertotal
    })
  })

  const data = {
    labels,
    datasets: Object.keys(sumResult).flatMap((value, i) => {
      return {
        type: 'bar',
        label: value,
        data: dataValues.flatMap((a) => a[i]),
        backgroundColor: colors[i],
      }
    }),
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Bar options={options} data={data} height={75} />
    </div>
  );
}

export default QuarterlyChart