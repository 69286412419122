import React, { useState } from 'react';
import axios from 'axios';
import { findIndex } from 'lodash';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { FormattedNumber } from 'react-intl';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ModalProviderImage from './ModalProviderImage';
import CloseIcon from '@material-ui/icons/Close';
import AddProvider from './AddProvider';
import Moment from 'react-moment';
import { TableFooter } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { apiUrl } from './vars';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'gray',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  table: {
    minWidth: 700,
  },
}));

const Providers = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [closeAddProviderModal, setCloseAddProviderModal] = useState('');
  const [totalAllProviders, setTotalAllProviders] = React.useState(0);
  const [form, setForm] = useState({
    _id: null,
    siret: '',
    provider: '',
    invoiceNumber: '',
    paymentMethod: '',
    date: null,
    image: '',
    value: '',
  });

  useState(() => {
    axios.post(`${apiUrl}/collections/get/${localStorage.client}_providers?token=${localStorage.token}`, {})
      .then(function (response) {
        console.log(response);
        setRows(response.data.entries);
        let total = 0;
        response.data.entries.map((invoice) => {
          total = total + invoice.value;
          setTotalAllProviders(total);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  });

  const saveProvider = () => {
    const data = { ...form };
    axios.post(`${apiUrl}/collections/save/${localStorage.client}_providers?token=${localStorage.token}`, { data })
      .then(function (response) {
        const newRows = [...rows];
        const rowExists = findIndex(newRows, ['_id', response.data._id]);
        if (rowExists >= 0) {
          newRows[rowExists] = response.data;
          setRows(newRows);
        } else {
          const providerRows = [...newRows, response.data];
          setRows(providerRows);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setForm({
      _id: null,
      siret: '',
      provider: '',
      invoiceNumber: '',
      paymentMethod: '',
      date: null,
      image: '',
      value: '',
    });

    setCloseAddProviderModal('close');
  }

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  }

  const seePhoto = (row) => {
    setPreviewImage(`/upload/${row.image}`);
  }
  const handleCloseModal = () => {
    setPreviewImage('');
  }

  const changePhoto = (value) => {
    setForm((prevState) => ({ ...prevState, image: value }));
  }

  const changeSiret = ({ siret, name }) => {
    setForm((prevState) => ({ ...prevState, siret }));
    setForm((prevState) => ({ ...prevState, provider: name }));
  }

  const handleChangeDate = (date) => {
    setForm((prevState) => ({ ...prevState, date: date }));
  };

  const editRow = (row) => {
    setCloseAddProviderModal('open');
    setForm({
      _id: row._id,
      siret: row.siret,
      provider: row.provider,
      invoiceNumber: row.invoiceNumber,
      paymentMethod: row.paymentMethod,
      date: row.date,
      image: row.image,
      value: row.value,
    });
  }

  const resetCloseAddProviderModal = () => {
    setCloseAddProviderModal('')
  }

  return (
    <div style={{ padding: '25px', paddingBottom: '95px' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Siret</StyledTableCell>
              <StyledTableCell style={{ whiteSpace: 'nowrap' }}>Nom du fournisseur</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>N. Facture</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>Mode de paiement</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>Date</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>Photo</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>Montant</StyledTableCell>
              <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell component="th" style={{ whiteSpace: 'nowrap' }} scope="row">{row.siret ? row.siret : '-'}</StyledTableCell>
                <StyledTableCell component="th" style={{ whiteSpace: 'nowrap' }}>{row.provider}</StyledTableCell>
                <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>{row.invoiceNumber}</StyledTableCell>
                <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>{row.paymentMethod}</StyledTableCell>
                <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                  <Moment format="DD-MM-YYYY" style={{ whiteSpace: 'nowrap' }}>
                    {row.date}
                  </Moment>
                </StyledTableCell>
                <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                  {row.image ? (
                    <IconButton color="primary" aria-label="see picture" component="span" onClick={() => seePhoto(row)}>
                      <PhotoCamera />
                    </IconButton>
                  ) : (
                    <IconButton color="primary" aria-label="see picture" component="span" onClick={() => { }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedNumber value={row.value} style="currency" currency="EUR" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton variant="contained" onClick={() => editRow(row)}><EditIcon /></IconButton >
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} align="right" style={{ fontSize: 14, color: '#000' }}>
                Total
              </TableCell>
              <TableCell align="right" style={{ fontSize: 14, color: '#000' }}>
                <FormattedNumber value={totalAllProviders} style="currency" currency="EUR" />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {previewImage && (
        <ModalProviderImage previewImage={previewImage} handleCloseModal={handleCloseModal} />
      )}
      <AddProvider
        handleChangeForm={handleChangeForm}
        saveProvider={saveProvider}
        form={form}
        closeAddProviderModal={closeAddProviderModal}
        resetCloseAddProviderModal={resetCloseAddProviderModal}
        changePhoto={changePhoto}
        handleChangeDate={handleChangeDate}
        changeSiret={changeSiret}
      />
    </div>
  );
}

export default Providers;
