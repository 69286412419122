const primeiraCasa = [
    "zéro",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
];

const segundaCasa = [
    '',
    'dix',
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingt",
    "quatre-vingt-dix"
];

const terceiraCasa = [
    '',
    'cent',
    "deux cents,",
    "trois cents,",
    'quatre cents,',
    'cinc cents,',
    'six cents,',
    'sept cents,',
    'huit cents,',
    'neuf cents,'
];

module.exports = [
    primeiraCasa,
    segundaCasa,
    terceiraCasa
];
